.googleContinuePopup {
    .search__switcher {
        display: block;
        margin: 0 auto;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
    }

    &__btn {
        margin-top: 30px;
    }
}
