@import '../../../styles/variables.scss';
@import '../../../styles/commonStyles.scss';

.flex {
   display: flex;
}

.flexColumn {
   display: flex;
   flex-direction: column;
}

// ...............................

.confirm {

   &__closeBtn {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
   }

   &__title {
      @extend .popupTitle;
      text-align: center;
   }

   &__imageName {
      align-items: center;
   }

   &__walkerInfo {
      margin-top: 50px;
      align-items: flex-start;
      justify-content: space-between;

      > div {
         margin-right: 20px;
      }
   }

   &__walkerImg {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      margin-right: 15px;
      overflow: hidden;
      box-shadow: 2px 2px 6px rgba(48, 61, 86, 0.15);
      
      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &__walkerName {
      color: $darkBlue;
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;
   }

   &__walkerPosition {
      @include plainText;
      font-weight: 300;
      font-size: 12px;
   }

   &__dateWrapper {
      margin-right: 80px;
   }

   &__date {
      color: $darkBlue;
      font-size: 16px;
      font-weight: 500;
      margin-top: 0px;
   }

   &__time {
      @extend .confirm__date;
   }

   &__detailsSection {
      margin-top: 40px;
      justify-content: space-between;
   }

   &__detailsWrapper {
      flex: 1;
      margin-right: 30px;
   }

   &__chooseDogsLabel {
      margin-bottom: 10px;
   }
   
   &__chooseDogsWrapper {
      margin: 1px 0;
   }

   &__addDogBtn {
      font-size: 12px;
      padding: 5px 10px;
      background: $pink;
      color: #fff;
      font-weight: 500;
      border-radius: 10px;
      transition: $transition;
      cursor: pointer;

      &:hover {
         background: darken($color: $pink, $amount: 7);
      }
   }

   &__addDogBtn--error {
      animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
   }

   &__selectedDog {
      color: #fff;
      background: #ff8f85d0;
      position: relative;
      overflow: hidden;
      padding: 1px 10px 1px 1px;
      font-size: 14px;
      cursor: pointer;
      border-radius: 20px;
      display: flex;
      align-items: center;
   }
   
   &__dogsImg {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 5px;
      margin: 2px;
      margin-right: 7px;
      border: 1px solid #fff;
      
      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
      
   &__dogsName {
      position: relative;
      overflow: hidden;
      padding: 1px 10px 1px 1px;
      font-size: 14px;
      color: #90929d;
      cursor: pointer;
      border-radius: 20px;
      display: flex;
      align-items: center;
   }

   &__label {
      color: $darkGrey;
      font-size: 12px;
      font-weight: 300;
   }

   &__addressWrapper {
      margin-top: 10px;
      display: flex;
   }

   &__addressLabel {
      color: $greyDescText;
      font-size: 12px;
      font-weight: 400;
      margin-left: 10px;
   }

   &__dateLabel {
      margin-top: 7px;
   }

   &__timeLabel {
      margin-top: 7px;
   }

   &__details {
      width: 100%;
      max-width: 100%;
      height: 100px;
      box-shadow: 2px 2px 6px rgba(48, 61, 86, 0.15);
      outline: none;
      border-radius: 12px;
      padding: 15px;
      color: $darkGrey;
      font-size: 14px;
      border: none;
      margin-top: 8px;
      resize: none;
      box-sizing: border-box;
      font-family: 'Poppins', sans-serif;

      &::placeholder {
         color: #90929d;
      }
   }

   &__detailsError {
      @extend .confirm__details;
      border: 1px solid rgb(236, 142, 142);
      box-shadow: 2px 2px 6px rgba(240, 15, 34, 0.15);
   }

   &__paymentMethodsWrapper {
      margin-top: 20px;
   }

   &__paymentMethods {
      display: flex;
      margin-top: 16px;
   }

   &__paymentMethodBtn  {
      padding: 5px 23px;
      box-shadow: 2px 2px 6px rgba(48, 61, 86, 0.15);
      border-radius: 8px;
      font-size: 14px;
      font-weight: 400;
      transition: $transition;
      cursor: pointer;
      color: #90929d;
      border: 1px solid transparent;
      background: #ffffff;

      &:last-child {
         margin-left: 24px;
      }

      &:hover {
         background: #ff8f8531;
         color: darken($color: $pink, $amount: 7);
         border: 1px solid $pink;
         box-sizing: border-box;
      }
   }

   &__paymentMethodActive {
      background: $pink;
      color: #ffffff;
      font-weight: 500;

      &:hover {
         background: darken($color: $pink, $amount: 7);
         color: #fff;
      }
   }

   &__paymentMethodDisable {
      background: #eee;
      cursor: not-allowed;
      padding: 5px 23px;
      box-shadow: 2px 2px 6px rgba(48, 61, 86, 0.15);
      border-radius: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #90929d;
      border: 1px solid transparent;
   }

   // for cash

   &__amountChange {
      color: $darkGrey;
      font-size: 14px;
      padding: 10px 15px;
      box-shadow: 2px 2px 6px rgba(48, 61, 86, 0.15);
      border-radius: 12px;
      outline: none;
      width: 100%;
      border: none;
      margin-top: 24px;
      box-sizing: border-box;
      font-family: 'Poppins', sans-serif;

      &::placeholder {
         color: #90929d;;
      }
   
   }

   &__amountChangeError { 
      @extend .confirm__amountChange;
      border: 1px solid rgb(236, 142, 142);
      box-shadow: 2px 2px 6px rgba(240, 15, 34, 0.15);
   }

   //  on click to 'add new'

   &__addCardInputs {
      margin-top: 16px;
   }

   &__addCardInput {
      width: 100%;
      box-sizing: border-box;
      box-shadow: 2px 2px 6px rgba(32, 33, 65, 0.12);
      border-radius: 8px;
      color: $darkBlue;
      font-size: 14px;
      padding: 10px 15px;
      border: none;
      outline: none;
      margin-top: 16px;
      font-family: 'Poppins', sans-serif;

      &::placeholder {
         color: #90929d;
         font-weight: 300;
      }

      &:last-child {
         margin-left: 30px;
      }
   }

   &__addCardBtn {
      color: darken($color: $pink, $amount: 5);
      margin-top: 20px;
      border: 1px solid $pink;
      width: fit-content;
      border-radius: 7px;
      background: #ff8f8527;
      font-size: 14px;
      padding: 4px 20px;
      transition: $transition;
      cursor: pointer;

      &:hover {
         background: #ff8f856e;
         color: #fff;
      }
   }
   
   // for credit cards 

   &__addCardGroup {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
   }

   &__cardLabel {
      font-size: 14px;
      font-weight: 400;
      color: $darkBlue;
   }

   &__addCard {
      cursor: pointer;
      margin-top: 0;
   }

   &__addCard:hover .confirm__addCardText {
         color: darken($color: $pink, $amount: 7);
   }

   &__addCardText {
      color: $darkBlue;
      font-size: 15px;
      margin-left: 8px;
      transition: $transition;
   }

   // card section

   &__cards {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      justify-content: space-between;
   }

   &__card {
      padding: 20px 16px;
      width: 220px;
      margin: 5px 0 5px 0;
      margin-top: 5px;
      margin-bottom: 5px;
      height: 120px;
      color: $darkGrey;
      cursor: pointer;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: $transition;
      background-image: linear-gradient(to right bottom, #f7f9fb, #f8f8fb, #f9f8fb, #faf7fa, #fcf6f8, #fdf3f5, #feeff1, #ffecec, #ffe5e5, #ffdede, #ffd7d7, #ffd0d0);
      background-size: 300% 100%;
      cursor: pointer;

      &:hover {
         background-position: 100% 0;
         transition: $transition;
      }
   }

   &__card--active {
      @extend .confirm__card;
      color: #ffffff;
      background-image: linear-gradient(to right bottom, #ff9085, #fe9b8e, #fea597, #fcafa1, #fbb9ab);
      pointer-events: none;
   }

   &__card--active .confirm__cardDateLabel {
      color: #ffffff;
   }   

   &__cardNumLogo {
      display: flex;
      justify-content: space-between; 
      align-items: center; 
   }

   &__cardNum {
      font-size: 13px;
      font-weight: 500;
   }

   &__cardDateLabel {
      font-size: 10px;
      font-weight: 500;
      color: $darkGrey;
   }

   &__cardDate {
      font-size: 13px;
      font-weight: 500;
      margin-top: 10px;
   }

   &__priceSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 32px;
   }

   &__price {
      font-size: 24px;
      color: $darkBlue;
      margin-top: 16px;
   }

   // pay btn

   &__confirmBtn {
      background: $pink;
      border-radius: 7px;
      color: #ffffff;
      padding: 8px 35px;
      text-align: center;
      border: none;
      font-size: 15px;
      font-weight: 500;   
      margin: 0 auto;
      display: block;
      outline: none;
      margin-top: 35px;
      transition: $transition;
      cursor: pointer;
      font-family: 'Poppins', sans-serif;
   
      &:hover {
         background: darken($color: $pink, $amount: 7);
      }
   }

   &__payWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 35px;
   }

   &__priceLabel {
      font-size: 15px;
      color: #90929d;
   }

   &__priceValue {
      font-size: 17px;
      color: $darkBlue;
   }

   &__payBtn {
      @extend .confirm__confirmBtn;
      margin: 0;
   }
}



@keyframes shake {
   10%, 90% {
     transform: translate3d(-1px, 0, 0);
   }
   
   20%, 80% {
     transform: translate3d(2px, 0, 0);
   }
 
   30%, 50%, 70% {
     transform: translate3d(-4px, 0, 0);
   }
 
   40%, 60% {
     transform: translate3d(4px, 0, 0);
   }
 }
 