.dogSize {
    position: relative;
    padding: 0;
    margin: 0;
    padding: 3px 6px 3px 39px;
    flex: none;
    width: max-content;

    &__img {
        width: 23px;
        position: absolute;
        top: 52%;
        transform: translateY(-50%);
        left: 10px;
        z-index: 6;
        height: 100%;

        img {
            width: 100%;
        }
    }

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 6px;
        z-index: 2;
        background: #fff;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    &__bgCircle {
        top: 50%;
        transform: translateY(-50%);
        left: 4px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        position: absolute;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background: #fff;
        z-index: 1;
    }

    &__bgCircle--top {
        top: 50%;
        transform: translateY(-50%);
        left: 4px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        position: absolute;
        background: #fff;
        z-index: 3;
    }


    &__size {
        z-index: 5;
        display: block;
        position: relative;
        font-size: 12px;
    }
}