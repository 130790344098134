@import '../../../styles/variables.scss';

.flex {
   display: flex;
   flex-wrap: wrap;
}

.popupTitle {
   font-size: 32px;
   font-weight: 500;
   color: $darkBlue;
}


// .....

.addDog {

   &__closeBtn {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
   }

   &__img {
      width: 100px;
      height: 100px;
      background: #FFFFFF;
      border: 1px solid #eee;
      box-shadow: 0px 2px 5px rgba(82, 87, 108, 0.1);
      border-radius: 50%;
      position: relative;
      margin: 0 auto;
      cursor: pointer;
      overflow: hidden;
   }

   &__addAnotherBtn {
      color: $pink;
      font-size: 10px;
      border-bottom: 1px solid transparent;
      width: fit-content;
      margin: 0 auto;
      margin-top: 10px;
      cursor: pointer;

      &:hover {
         border-bottom: 1px solid $pink;
      }

   }

   &__photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
   }

   &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }

   &__imgIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
   }

   &__name {
      border: 1px solid #eee;
      border-radius: 8px;
      background: #FFFFFF;
      box-shadow: $shadow;
      padding: 12px 15px !important;
      color: $darkBlue;
      width: 100%;
      box-sizing: border-box;
      margin-top: 16px;
      outline: none;
      font-size: 15px;

      &::placeholder {
         color: #90929d;
      }
   }

   &__nameError {
      @extend .addDog__name;
      border: 1px solid rgb(236, 142, 142);
      box-shadow: 2px 2px 6px rgba(240, 15, 34, 0.15);
   }
   
      &__label {
         margin-top: 20px;
         margin-bottom: 5px;
         font-size: 12px;
         color: #646774;
      }

      &__btn {
         border-radius: 8px;
         border: 1px solid #eee;
         transition: $transition;
         cursor: pointer;
         outline: none;
         box-sizing: border-box;
         background: #ffffff;
         padding: 8px 24px;
         font-size: 14px;

         &:hover {
            border: 1px solid lighten($color: $pink, $amount: 10);
         }
      }

      &__weightButtons {
         display: flex;
   
         input {
            display: none;
         }
   
         input:checked + label {
            background: $pink !important;
            color: #ffffff;
            border: 1px solid $pink;
        }
      }
   
      &__weightBtn {
         color: $darkBlue;
         margin-right: 14px !important;
         background: #ffffff;
      }

      &__info {
         display: flex;
         flex-direction: column;
         font-size: 14px;
         margin-right: 50px;
         position: relative;

         .MuiFormControl-root {
            margin-top: 0;
         }
      }

      &__ageBtn {
         width: 200px;
         border-radius: 8px !important;
         border: 1px solid #eee !important;
         padding: 8px 20px;
         
         input {
            font-size: 14px;
            padding: 9px 15px;
         }

         fieldset {
            border: none !important;
         }
      }

      &__sexButtons {
         display: flex;
   
         input {
            display: none;
         }
   
         input:checked + label {
            background: $pink !important;
            color: #ffffff;
            border: 1px solid $pink;
        }
      }
   
      &__sexBtn {
         // color: $darkBlue;
         margin-right: 14px !important;
         // background: #ffffff;
      }

      &__comment {
         width: 100%;
         box-sizing: border-box;
         border: none;
         box-shadow: $shadow;
         border: 1px solid #eee;
         border-radius: 8px;
         background: #fff;
         resize: none;
         outline: none;
         padding: 16px;
         font-size: 14px;
         font-family: 'Poppins', sans-serif;

         &::placeholder {
            color: #90929d;
         }
      }

      &__commentError {
         @extend .addDog__comment;
         border: 1px solid rgb(236, 142, 142);
         box-shadow: 2px 2px 6px rgba(240, 15, 34, 0.15);
      }

      &__saveBtn {
         background: $pink;
         color: #fff;
         font-size: 14px;
         padding: 8px 37px;
         border-radius: 7px;
         width: fit-content;
         margin: 0 auto;
         margin-top: 24px;
         transition: $transition;
         cursor: pointer;

         &:hover {
            background: darken($color: $pink, $amount: 7);
         }
      }

      &__saveBtnDisable {
         @extend .addDog__saveBtn;
         background: #eee;
         pointer-events: none;

         &:hover {
            background: #eee;
         }
      }
   }

   // image uploader

   .imageUpLoader__wrapper {
      background: #fff;
      box-shadow: 4px 4px 24px rgba(85, 106, 125, 0.2);
      border-radius: 28px;
      margin-bottom: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 85%;
      height: 85%;
      transform: translate(-50%, -50%);
      z-index: 10;
   }

   .imageUpLoader {
      height: 70%;
      width: 90%;
      margin: 0 auto;
      margin-top: 30px;
      border: 2px dashed $pink;
      overflow: hidden;
      border-radius: 7px;
      position: relative;
      transition: .1s ease-in-out;

      &:hover {
         background: #ff8f8508;
      }

      &__image {
         position: absolute;
         top: 0;
         left: 50%;
         transform: translateX(-50%);
         height: 100%;
      }

      &__input {
         opacity: 0;
         top: 0;
         position: absolute;
         width: 100%;
         height: 100%;
         left: 0;
         cursor: pointer;
      }
     

     &__clickableArea {
         width: 100%;
         height: 100%;
     }

     &__selectBtn {
         position: absolute;
         background: #ff8f8531;
         color: darken($color: $pink, $amount: 7);
         padding: 5px 20px;
         border-radius: 20px;
         bottom: 50%;
         left: 50%;
         transform: translate(-50%, 50%);
         transition: $transition;
         z-index: 100;
         pointer-events: none;
     }

     &__customBtnWrapper {
         display: flex;
         align-items: center;
         font-size: 14px;
         width: fit-content;
         margin: 0 auto;
         cursor: pointer;
         text-transform: uppercase;
         transition: .2s ease-in-out;

         img {
            width: 30px;
            margin-right: 10px;
         }
     }

      &__deletePhoto {
         position: absolute;
         background: #ffffffea;
         color: rgb(206, 81, 81);
         bottom: 15px;
         font-weight: 500;
         right: 15px;
         font-size: 12px;
         padding: 3px 5px;
         cursor: pointer;
         border-radius: 10px;
         transition: $transition;
         display: flex;
         align-items: center;

         &:hover {
            background: #fff
         }
      }

      &__buttons {
         margin: 0 auto;
         display: flex;
         margin-top: 20px;
         width: fit-content;
      }

      &__btn {
         border-radius: 7px;
         font-size: 14px;
         font-weight: 500;
         padding: 5px 40px;
         text-align: center;
         transition: $transition;
         cursor: pointer;
      }

      &__uploadBtn {
         @extend .imageUpLoader__btn;
         border: 1px solid $pink;
         background: $pink;
         color: #fff;
         margin-right: 20px;
         
         &:hover {
            background: darken($color: $pink, $amount: 7);
         }
      }

      &__cancelBtn {
         @extend .imageUpLoader__btn;
         border: 1px solid $pink;
         color: $pink;

         &:hover {
            background: #ff8f8528;
         }
      }
   }

   .textError {
      color: rgb(186, 38, 38);
      font-size: 11px;
      text-align: center;
      margin-top: 5px;
   }