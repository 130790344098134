@import '../../styles/variables.scss';

.statusBadge {
    position: absolute;
      top: -18px;
      padding: 5px 20px;
      font-size: 14px;
      border-radius: 30px;
      left: 50%;
      transform: translateX(-50%);
      text-transform: capitalize;
      z-index: 10;
}

.statusBadge--progress {
    @extend .statusBadge;
    background: $pink;
    color: #fff;
}

.statusBadge--booked {
    @extend .statusBadge;
    background: $lightBlue;
    color: $darkBlue;
}

.statusBadge--past {
    @extend .statusBadge;
    background: lighten($color: $darkBlue, $amount: 30);
    color: #fff;
}

.statusBadge--canceled {
    @extend .statusBadge;
    background: $darkBlue;
    color: #fff;
}