@import '../../styles/variables.scss';

.contactDialog {
   position: absolute;
   bottom: 70px;
   background: #fff;
   padding: 12px 20px;
   border-radius: 12px;
   box-shadow: 4px 4px 12px rgba(85, 106, 125, 0.3);
   display: flex;
   left: 50%; 
   transform: translateX(-50%);
   align-items: center;
   z-index: 102;

   &__walkerImg {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      overflow: hidden;
      flex: none;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &__walkerInfo {
      display: flex;
      flex-direction: column;
      text-align: start;
      margin-left: 20px;
   }

   &__walkerName {
      color: $darkBlue;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
   }

   &__walkerPosition {
      color: $lightGrey;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
   }

   &__icons {
      margin-left: 120px;
      display: flex;
   }

   &__icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: $pink;
      position: relative;
      transition: $transition;
      cursor: pointer;

      &:hover {
         background: darken($color: $pink, $amount: 7);
      }

      img {
         position: absolute;
         width: 20px;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }

      &:last-child {
         margin-left: 16px;
      }
   }

   &__chatWrapper {
      right: 20px;
      bottom: 70px;
      width: 350px;
      opacity: 0;
      height: 450px;
      position: absolute;
   }

   &__chatWrapper--active {
      animation-name: fadeIn;
      animation-duration: .2s;
      width: 350px;
      height: 450px;
      position: absolute;
      bottom: 70px;
      right: 20px;
      opacity: 1;
      z-index: 10;
   }
}