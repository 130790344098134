@import '../../../styles/variables.scss';

.shelterDetails {
    color: $darkBlue;
    padding: 50px;

    &__imgWrapper {
        width: 100%;
        border-radius: 24px;
        overflow: hidden;
        height: 150px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__nameWrapper {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
    }

    &__name {
        font-size: 20px;
        font-weight: 500;
        display: flex;
        flex-direction: column;
    }

    &__socials {
        display: flex;
        align-items: center;
        grid-gap: 15px;
    }

    &__social {
        cursor: pointer;
        transition:  $transition;

        &:hover {
            opacity: .8;
        }

        a {
            color: $darkBlue !important;
        }
    }

    &__label {
        color: $lightGrey;
        font-size: 12px;
    }

    &__locationWrapper {
        margin-top: 20px;
        display: flex;
    }

    &__location {
        margin-left: 15px;
    }

    &__mapWrapper {
        width: 100%;
        height: 300px;
        margin-top: 20px;
    }
}

@media (max-width: 540px) {
    .shelterDetails {
        line-height: 20px;
        padding: 20px;
    }
    
}
