@import '../../../styles/variables.scss';

.searchBox {
   width: 100%;
   padding: 10px 25px;
   height: 100px;
   box-sizing: border-box;
   background: #e9f1f8;
   border-radius: 24px;
   margin-top: 30px;
   box-shadow:  2px 2px 12px #292d5413;
   font-size: 19px;
   display: flex;
   justify-content: space-between;
   
   &__label {
      font-size: 13px;
      color: #5c6373;
      text-align: start;
   }

   &__btn {
      box-shadow: 2px 2px 8px rgba(32, 33, 65, 0.2);
      border-radius: 8px;
      border: none;
      transition: $transition;
      cursor: pointer;
      font-size: 16px;
      outline: none;
      box-sizing: border-box;
      background: #ffffff;

      &:hover {
         box-shadow: 1px 1px 10px rgba(12, 13, 26, 0.3);
      }
   }

   &__weightDate {
      display: flex;
   }
   

   &__weight {
      width: 100%;
      width: fit-content;
   }

   &__weightButtons {
      display: flex;

      input {
         display: none;
      }

      input:checked + label {
         background: $pink !important;
         color: #ffffff;
     }
     input:checked + label .searchBox__dogIconWrapper {
        background: $pink;
     }
   }

   &__weightBtn {
      color: $darkBlue;
      margin-right: 14px !important;
      padding: 3px 10px 2px 60px;
      background: #ffffff;
      margin: 6px 0px;
      display: flex;
      align-items: center;
      position: relative;
      
      div {
         line-height: 30px;
      }
   }

   &__dogIconWrapper {
      background: #fff;
      width: 45px;
      height: 45px;
      padding: 5px;
      border-radius: 50%;
      position: absolute;
      top: -5px;
      left: 7px;
      box-sizing: border-box;
      transition: $transition;
   }

   &__dogIcon {
      width: 35px;
      height: 35px;

   }
   
   &__date {
      width: fit-content;
      margin-left: 30px;

      input {
         font-size: 16px;
      }
   }
   
   &__dateBtn {
      width: 175px;
      margin: 6px 0 !important;
      border-radius: 8px;
   }

   &__datePickerIcon {
      width: 18px;
      height: 18px;
   }

   &__locationSearchBtn {
      display: flex;
      flex: 1;
   }

   &__placeWrapper {
      margin-left: 20px;
      position: relative;
      flex: 1;
      margin: 0 40px;
   }

   &__placeInputWrapper {
      position: relative;
   }

   &__placeInputImg {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
   }

   &__placeInputArrow {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: $pink;
   }

   &__placeInput {
      padding: 8px 44px 9px 35px;
      width: 100%;
      background: #ffffff;
      margin: 6px 0;
      box-sizing: border-box;
      box-shadow: 2px 2px 8px rgba(32, 33, 65, 0.2);
      border: none;
      border-radius: 8px;
      font-size: 16px;
      outline: none;

      &::placeholder {
         color: $darkBlue;
      }
   }

   &__suggestions {
      z-index: 100 !important;
      position: absolute;
      width: 100%;
      border: 2px solid  #e9f1f8;
   }

   &__places {
      background: #fff;
      text-align: start;
      cursor: pointer;
      font-size: 16px;
      padding: 3px 10px;
      border-bottom: 1px solid #e9f1f8;
   }

   &__places--active {
      @extend .searchBox__places;
      background: $pink;
      color: #ffffff;
   }


   &__searchBtn {
      padding: 6px 38px;
      background: $pink;
      color: #ffffff;
      align-self: center;
      height: fit-content;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 16px;
      border-radius: 7px;
      cursor: pointer;
      transition: $transition;
      margin-left: 10px;
      &:hover {
         background: darken($color: $pink, $amount: 5)
      }
   }
}

@media (max-width: 1100px) {
   .searchBox {
      flex-direction: column;
      height: fit-content;
      width: fit-content;
      margin-top: 50px;
      padding: 20px;

      &__placeWrapper {
         margin-left: 0;
         flex: 2;
      }

      &__searchBtn {
         flex: 1;
         align-self: flex-end;
         padding: 6px 30px;
         margin-left: 0;
         margin-bottom: 5px;
      }
   }
}