@import '../../../styles/variables.scss';
@import '../../../styles/commonStyles.scss';

.flex {
   display: flex;
}

.closeBtn {
   position: absolute;
   top: 24px;
   right: 24px;
   cursor: pointer;
}

// .....

.walks {
   position: relative;
   padding: 60px 190px 50px 190px;

   &__closeBtn {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
   }

   &__title {
      @extend .popupTitle;
      text-align: center;
   }
   
   &__types {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      width: fit-content;
      margin: 0 auto;
   }

   &__type {
      font-size: 16px;
      color: $lightGrey;
      font-weight: 400;
      cursor: pointer;
      transition: $transition;
      margin: 0 20px;

      &:hover {
         color: $pink;
      }
   }

   &__type--active {
      color: $pink;
      font-weight: 500;
   }

   &__group {
      background: #ffffff;
      color: $darkBlue;
      margin: 0 auto;
      margin-top: 24px;
      width: 80%;
      box-shadow: 0px 4px 12px rgba(88, 111, 138, 0.17);
      border-radius: 24px;
      display: flex;
      align-items: center;
      padding: 10px 15px 10px 10px;
   }
   
   &__walkerImg {
      width: 90px;
      height: 90px;
      overflow: hidden;
      border-radius: 18px;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &__desc {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
   }
   
   &__walkerName {
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;
   }
   
   &__info {
      display: flex;
      margin-top: 8px;
   }
   
   &__infoBox {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
   }

   &__label {
      text-transform: capitalize;
      font-size: 12px;
      color: #646775;
      font-weight: 300;
   }

   &__date {
      font-size: 14px;
   }

   &__time {
      @extend .walks__date;
   }

   &__edit {
      padding: 7px 30px;
      color: #fff;
      background: $pink;
      font-size: 14px;
      font-weight: 500;
      border-radius: 8px;
      margin-left: auto;
      transition: $transition;
      cursor: pointer;

      &:hover {
         background: darken($color: $pink, $amount: 7);
         color: #ffffff;
      }
   }
}

@media (max-width: 992px) {
   .walks {
      padding: 60px;
   }
}