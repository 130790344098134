@import '../../styles/variables.scss';

.shelter {
    border-radius: 18px;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(88, 111, 138, 0.17);
    padding: 16px 20px;
    text-align: start;
    color: $darkBlue;
    font-size: 14px;
    border: 1px solid transparent;

    &--active {
        @extend .shelter;
        border: 1px solid $pink;
    }

    &__infoWrapper {
        display: flex;
    }

    &__imgWrapper {
        border-radius: 10px;
        background-color: #FCEEEB;
        width: 120px;
        // height: 120px;
        height: auto;
        overflow: hidden;
        box-shadow: 0px 2.138852596282959px 6.416557788848877px 0px rgba(88, 111, 138, 0.17);
  
        img {
           height: 100%;
           width: 100%;
           object-fit: cover;
        }
     }

    &__info {
        margin-left: 20px;
        flex: 1;
    }

    &__nameWrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    &__name {
        font-size: 16px;
        font-weight: 500;
    }

    &__dogsCount {
        color: $lightGrey;
        white-space: nowrap;
    }

    &__label {
        color: $lightGrey;
    }

    &__buttons {
        width: fit-content;
        display: flex;
        margin-left: auto;
        margin-top: 20px;
        grid-gap: 15px;
    }

}

@media (max-width: 540px) {
    .shelter {
        &__imgWrapper {
            background: #FAEEEB;

            img {
                object-fit: contain;
            }
        }
    }
}