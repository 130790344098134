@import '../../../styles/variables.scss';
@import '../../../styles/commonStyles.scss';

.feedback {
   position: relative;
   padding: 60px 190px 50px 190px;

   &__closeBtn {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
   }

   &__img {
      width: 64px;
      height: 64px;
      background: #FFFFFF;
      box-shadow: 6px 6px 12px rgba(85, 106, 125, 0.25);
      border-radius: 50%;
      margin: 0 auto;
      margin-top: 25px;
      overflow: hidden;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &__title {
      @extend .popupTitle;
      text-align: center;
   }

   &__walkerName {
      color: $darkBlue;
      font-size: 14px;
      margin-top: 12px;
      text-align: center;
      text-transform: capitalize;
   }

   &__titleText {
      font-size: 18px;
      font-weight: 500;
      color: $darkBlue;
      text-align: center;
      margin-top: 24px;
   }

   &__plainText {
      font-size: 12px;
      font-weight: 300;
      color: #90929d;
      text-align: center;
      margin-top: 8px;
   }

   &__starRate {
      margin: 0 auto;
      margin-top: 16px;
      width: fit-content;
   }

   &__comment {
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px rgba(27, 34, 61, 0.1);
      border-radius: 12px;
      border: none;
      outline: none;
      padding: 16px;
      font-size: 14px;
      color: $darkBlue;
      height: 100px;
      resize: none;
      font-family: 'Poppins', sans-serif;

      &::placeholder {
         color: $lightGrey;
      }
   }

   &__saveBtn {
      background: $pink;
      color: #ffffff;
      font-size: 14px;
      transition: $transition;
      border-radius: 7px;
      border: 2px solid transparent;
      cursor: pointer;
      padding: 8px 35px;
      width: fit-content;
      margin: 0 auto;
      margin-top: 24px;

      &:hover {
         background: darken($color: $pink, $amount: 7)
      }
   }
}

   
@media (max-width: 992px) {
   .feedback {
      padding: 60px;
   }
}