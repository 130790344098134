@import '../../../styles/variables.scss';
@import '../../../styles/commonStyles.scss';


.auth {

   .search__switcher {
      margin-top: 20px;
   }

   &__title {
      @extend .popupTitle;
      display: flex;
      align-items: center;
   }

   &__formWrapper {
      display: flex;
      padding: 60px 60px 60px 20px;
      position: relative;
   }

   &__formClose {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
   }

   &__formImg {
      margin-top: 80px;
      img {
         width: 300px;
      }
   }

   &__form {
      flex: 1;
      box-sizing: border-box;
      margin-left: 20px;
         
        .MuiStepIcon-root.Mui-active {
                  color: $pink !important;
               }

               .MuiStepIcon-root.Mui-completed {
                  color: $pink !important;
               }

   }

   &__tip {
      color: $greyDescText;
      font-size: 13px;
      margin-bottom: -5px;
   }

   &__formField {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      height: 60px;
      margin-top: 5px;
      position: relative;
   }
   
   &__formFieldAutocomplete {
      flex-direction: column;
   }

   &__formLabel {
      transition: $transition;
      touch-action: manipulation;
      transform: translate(15px, 20px) scale(1);
      color: #8d909b;
      background: #ffffff;
      border-radius: 5px;
      width: fit-content;
      padding: 0 4px;
      pointer-events: none;
      font-weight: 400;
      font-size: 13px;
   }

   &__formInput {
      background: #FFFFFF;
      box-shadow: 2px 2px 4px rgba(32, 33, 65, 0.08);
      border: 1px solid #eee;
      border-radius: 8px;
      padding: 15px 15px !important;
      margin-top: 10px;
      color: $darkBlue;
      font-size: 16px;
      font-weight: 400;
      outline: none;
      transition: .1s ease;
      touch-action: manipulation;
      outline: 2px solid transparent;
   }

   &__autocompleteInput {
      width: 100%;
      box-sizing: border-box;

      &::placeholder {
         color: #8d909b !important;
      }
   }

   &__formTextarea {
      width: 100%;
      box-sizing: border-box;
      resize: none !important;
      font-size: 14px;
      font-family: 'Poppins', sans-serif;

      &::placeholder {
         color: #8d909b !important;
      }

      &::-webkit-scrollbar-track {
         background-color: #f5f5f5 !important;
         border-radius: 5px;
     }

     &::-webkit-scrollbar {
         width: 8px !important;
         background-color: rgba(255, 255, 255, 0.098) !important;
         border-radius: 5px;
     }

     &::-webkit-scrollbar-thumb {
         background-color: lighten($color: $pink, $amount: 10) !important;
         border-radius: 5px;
         cursor: pointer !important;
     }
   }

   &__formInput--error {
      border: 1px solid #ef635e;
      outline: 2px solid #ef635e38;
   }

   &__formInput::placeholder {
      color: #ffffff;
   }

   &__formInput:placeholder-shown + .auth__formLabel {
      transform: translate(15px, 45px) scale(1);
      font-size: 14px;
      text-transform: inherit;
   }

   &__formInput:focus + .auth__formLabel {
      transform: translate(15px, 20px) scale(1);
      font-size: 13px;
   } 

   &__error {
      color: rgb(189, 0, 0);
      font-size: 12px;
      margin-top: 5px;
      font-weight: 500;
   }

   &__formAgreement {
      font-size: 13px;
      color: $darkBlue;
      margin-top: 35px;
      position: relative;
      display: flex;
      cursor: pointer;
   }

   &__formAgreementLabel {
      padding-left: 10px;
      cursor: pointer;
      font-size: 14px;

      a {
         color: $pink;
         transition: $transition;
         &:hover {
            color: darken($color: $pink, $amount: 8)
         }
      }
   }

   &__checkbox {
      opacity: 0;

      &:checked ~ .auth__checkboxCustom {
         background: #ff8f85ab;
         border: 1px solid $pink;
         
         &:after {
            content: url('../../../../public/images/ico/check.svg');
            color: #fff;
            font-size: 15px;
            position: absolute;
            top: -2px;
            left: 50%;
            transform: translateX(-50%);
         }
      }
   }

   &__checkboxCustom {
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border:  1px solid #aaacb2;
      border-radius: 4px;
      pointer-events: none;
   }

   &__formPassRecovery {
      text-align: right;
      margin-top: 12px;
      color: $darkBlue;
      font-size: 12px;
      font-weight: 400;
      opacity: .5;
      pointer-events: none;
   }

   &__formPassRecoveryLink {
      color: $pink;
      font-weight: 600;
      border-bottom: 1px solid transparent;
      transition: $transition;
      cursor: pointer;

      &:hover {
         border-bottom: 1px solid $pink;
      }
   }

   &__buttonsWrapper {
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
   }

   &__buttons {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      width: fit-content;
   }

   &__btn {
      padding: 8px 25px;
      border-radius: 7px;
      text-transform: capitalize;
      font-size: 14px;
      cursor: pointer;
      transition: $transition;
      font-weight: 500;
      margin-right: 16px;

      span {
         display: flex;
         align-items: center;
         grid-gap: 10px;
         text-transform: none !important;

         svg {
            font-size: 16px;
         }
      }
   }

   &__btn--pink {
      background: $pink;
      color: #ffffff;
      border: 2px solid transparent;

      &:hover {
         background: darken($color: $pink, $amount: 8);
      }
   }

   &__btn--plain {
      background: #ffffff;
      color: $pink;
      border: 2px solid $pink;

      &:hover {
         background: rgba($color: $pink, $alpha: .1);
         color: darken($color: $pink, $amount: 8);
      }
   }

   &__googleBtn {
      background: #4081ec;
      justify-content: center;
      padding: 5px 20px 5px 5px;
      font-size: 15px;
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: 600;
      align-self: center;
      transition: .1s ease-in-out;
      outline: none;
      border: none;
      cursor: pointer;
      border-radius: 40px;
      width: fit-content;

      img {
         margin-right: 15px;
         width: 20px;
         padding: 8px;
         border-radius: 50%;
         background: #fff;
      }

      &:hover {
         background: #4082ecdc;
      }
   }

   &__socialText {
      text-align: center;
      margin: 15px 0;
      color: $lightGrey;
      font-size: 13px;
      font-weight: 300;
   }

   &__socials {
      display: flex;
      margin: 0 auto;margin-top: 12px;
   }

   &__social {
      border: 1px solid #d3d4d8;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      position: relative;
      margin: 0 8px;
      transition: $transition;
      cursor: pointer;

      &:hover {
         background: rgba($color: $lightGrey, $alpha: .2);
      }

      img {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
   }

   // selection

   &__selectionWrapper {
      display: flex;
      width: 200px;
      grid-gap: 20px;
      margin-top: 20px;
   }

   &__selectItem {
      flex: 1;
      border-radius: 7px;
      border: 1px solid lighten($color: $pink, $amount: 10);
      color: $pink;
      transition: $transition;
      position: relative;
      padding: 7px 10px;
      cursor: pointer;
      text-align: center;
      font-size: 14px;
      font-weight: 500;

      &:hover {
         background: lighten($color: $pink, $amount: 18);
      }
   }

   &__selectImgWrapper {
      opacity: 0
   }

   &__selectItem--active {
      @extend .auth__selectItem;
      border: 1px solid transparent;
      background: lighten($color: $pink, $amount: 18);
      color: $pink;
      pointer-events: none;

      .auth__selectImgWrapper {
         opacity: 1;
      }
   }

   &__selectImgWrapper {
      position: absolute;
      border: 1px solid #fff;
      background: $pink;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      top: -10px;
      right: -10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
         width: 15px;
      }
   }


   &__generalInfoWrapper {
      opacity: 0;
      position: absolute;
      top: 0;
      pointer-events: none;
   }

   &__generalInfoWrapper--active {
      opacity: 1;
      animation: fadeIn .1s ease-in-out;
      margin-top: 20px;
   }

   &__shelterInfoWrapper {
      opacity: 0;
      display: none;
      position: absolute;
      top: 0;
      pointer-events: none;
   }

   &__shelterInfoWrapper--active {
      opacity: 1;
      margin-top: 20px;
      display: block;
      animation: fadeIn .1s ease-in-out;
   }

   &__notificationWrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #ffffff9f;
      z-index: 100;
      backdrop-filter: blur(1px);
   }

   &__notification {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      color: $darkBlue;
      font-weight: 600;
      text-align: center;
   }
}

.existUserMessage {
   margin-left: auto;
}

@media (max-width: 992px) { 
   .auth {
      &__formImg {
         display: none;
      }

      &__title {
         text-align: center;
      }

      &__buttonsWrapper {
         margin:  0 auto;
      }
   }
 } 

 @keyframes  fadeIn {
   0%   {
      opacity: .5;
   }

   100% { 
      opacity: 1;
   }
 }