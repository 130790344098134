.notFound {
    width: 500px;
    margin: 0 auto;
    margin-top: 100px;
    text-align: center;

    &__title {
        font-size: 24px;
        font-weight: 500;
    }

    &__text {
        font-size: 20px;
        color: #ddd;
        margin-top: 30px;
    }

    &__btn {
        margin-top: 30px;
    }
}