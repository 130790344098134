@import '../../styles/variables.scss';

a {
   text-decoration: none;
}

.menu {
   width: 1250px;
   max-width: 90%;
   margin: 0 auto;
   padding: 24px 0;
   display: flex;
   text-align: center;
   justify-content: space-between;

   &__wrapper {
      width: 100%;
      position: relative;
   }

   &__ellipseWrapper {
      position: absolute;
      top: 0;
      width: 100%;
      height: 700px;
      overflow: hidden;
      z-index: -5;
   }

   &__ellipseTopLeft {
      position: absolute;
      filter: blur(10px);
      -webkit-filter: blur(10px);
      width: 550px;
      height: 550px;
      left: -300px;
      border-radius: 50%;
      background: #f2f7fb;
   }

   &__ellipseTopRight {
      position: absolute;
      top: -300px;
      right: -300px;
      background: #ffdeda;
      width: 500px;
      height: 500px;
      filter: blur(10px);
      -webkit-filter: blur(10px);
      border-radius: 50%;

   }

   &__items {
      display: flex;
      align-self: flex-end;
      
      
      a {
         color: $lightGrey;
         font-size: 14px;
         transition: $transition;
          &:hover {
             color: $darkBlue;
          }
         
       }
   }

   &__logo {
      margin-right: 28px;
   }

   &__logoTablet {
      display: none;
   }

   &__item {
      font-size: 14px;
      text-transform: capitalize;
      margin: 0 20px;
      padding: 8px 0;
      font-weight: 500 !important;
   }

   &__item_active {
      color: $pink !important;
      position: relative;

      &::before {
         content: '';
         position: absolute;
         border-radius: 2px;
         transform: translateX(-50%);
         bottom: 3px;
         width: 20px;
         height: 2px;
         background: $pink;
      }
   }

   &__item--disabled {
      pointer-events: none !important;

      div {
         pointer-events: none !important;
         opacity: 0.3 !important;
         filter: alpha(opacity=30) !important;
         -moz-opacity: 0.3 !important;
         -khtml-opacity: 0.3 !important;
      }
   }

   &__buttons {
      display: flex;
      align-self: flex-end;
   }

   &__buttonsMobile {
      display: none;
      align-self: flex-end;
   }
   
   &__btn {
      padding: 7px 24px;
      border-radius: 7px;
      font-weight: 600;
      font-size: 14px;
      transition: $transition;
      cursor: pointer;
   }

   &__logIn {
      border: 2px solid $pink;
      color: $pink;
      text-transform: capitalize;

      &:hover {
         background: rgba($color: $pink, $alpha: .2);
         border: 2px solid #ff6557;
         color: #ff6557;
      }
   }

   &__signUp {
      background: $pink;
      color: #ffffff;
      border: 2px solid transparent;
      margin-left: 20px;
      text-transform: capitalize;

      &:hover {
         background: #ff6557;
      }
   }

   &__userName {
      text-transform: capitalize;
      color: $pink;
      font-size: 14px;
      margin-right: 0px;
      border-radius: 7px;
      padding: 7px 24px;
      font-weight: 500;
      flex: 0;
      border: 1.5px solid $pink;
      display: flex;
      align-items: center !important;
      transition: .2s ease-in-out;
      width: min-content;
      
      span {
         text-overflow: ellipsis;
         overflow: hidden; 
         max-width: 200px; 
         white-space: nowrap;
      }

      &:hover {
         background: lighten($color: $pink, $amount: 22);
      }

      img {
         margin-right: 8px;
      }
   }

   &__userName--active .menu__userName {
      background: lighten($color: $pink, $amount: 22);
   }

   &__logOut {
      @extend .menu__signUp;
   }
}

// popups 

.MuiBackdrop-root  {
   background-color: rgba($color: #ffffff, $alpha: .5) !important;
   backdrop-filter: blur(3px);
}

.MuiDialog-paper {
   border-radius: 28px !important;
   width: 920px;
   overflow: none !important;
}


.dialog--small .MuiDialog-paper {
   max-width: 300px;
}

.dialog .MuiDialogContent-root {
   &:first-child {
      padding: 0;
   }
}
.dialog .MuiDialogContent-root {
   &:nth-child(2){
      border-radius: 28px !important;
   }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 1000px) { 

   .menuRegular__wrapper {
      .menu {
         &__items {
            display: none;
         }  
      }
   }

   .menuLogged__wrapper {
      .menu {
         justify-content: center;

         &__logo {
            display: none;
         }
         
         &__logoTablet {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 50px;
            
            .menu__logoTabletImg {
               width: 120px;
               margin-top: 20px;
            }
         }

         &__buttons {
            display: none;
         }

         &__buttonsMobile {
            display: flex !important;
         }
      }
   }
}

@media (max-width: 460px) {
   .menuLogged__wrapper {
      .menu {
         justify-content: space-between;

         &__logoTablet {
               padding: 0 30px;
            }
         
            &__btn {
               border: 1px solid $pink;
               padding: 7px 15px;
            }
         
            &__signUp {
               margin-left: 10px;
            }
         }
   }
}