@import '../../styles/variables.scss';
@import '../../styles/commonStyles.scss';


 .futureWalksDialog {
   position: absolute;
   bottom: 70px;
   background: #fff;
   padding: 12px 20px;
   border-radius: 12px;
   box-shadow: 4px 4px 12px rgba(85, 106, 125, 0.3);
   left: 70px; 
   align-items: center;
   z-index: 103;
   
   &__title {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
   }
   
   &__futureWalk {
      position: relative;
      width: 100%;
      background: #fff;
      display: flex;
      align-items: center;
      font-size: 13px;
      padding: 5px 10px;
      border-radius: 5px;
      box-sizing: border-box;
      background: #fff;
      border: 1px solid #eee;
      margin-top: 5px;
      overflow: hidden;
   }

   &__mainInfoWrapper {
      display: flex;
      align-items: center;
      margin-right: 20px;
   }

   &__imgWrapper {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      
      img {
         object-fit: cover;
         width: 100%;
         height: 100%;
      }
   }

   &__walkerInfo {
      display: flex;
      flex-direction: column;
      text-align: start;
      margin: 0 10px;
      min-width: 60px;
   }

   &__walkerName {
      color: $darkBlue;
      line-height: 12px;
   }

   &__walkerPosition {
      color: $lightGrey;
      font-size: 10px;
      line-height: 12px;
   }

   &__dateTimeWrapper {
      display: flex;
      margin-left: auto;
   }

   &__date {
      margin-right: 20px;
   }

   &__colorTag {
      position: absolute;
      top: 0;
      right: 0;
      width: 5px;
      height: 100%;
      border-radius: 5px;
   }
}