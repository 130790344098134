@import '../../../styles/variables.scss';
@import '../../../styles/commonStyles.scss';

.editWalk {
   position: relative;
   padding: 60px 190px 50px 190px;

   &__closeBtn {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
   }

   &__title {
      @extend .popupTitle;
      text-align: center;
   }

   &__walkInfo {
      display: flex;
      // margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 30px;
      width: fit-content;
   }

   &__desc {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
   }

   &__datePickerWrapper {
      background: #e9f1f8;
      border-radius: 12px;
      width: fit-content;
      padding: 10px 20px !important;
      margin: 0 auto;
   }

   &__timeline {
      margin-top: 30px;
   }

   &__buttons {
      display: flex;
      margin: 0 auto;
      margin-top: 40px;
      width: fit-content;
   }

   &__cancelBtn {
      @extend .button;
      background: #fff;
      color: $pink;
      border: 2px solid $pink;

      &:hover {
         background: rgba($color: $pink, $alpha: .2);
      }
   }

   &__saveBtn {
      @extend .button;
      background: $pink;
      color: #ffffff;
      border: 2px solid transparent;
      margin-left: 10px;

      &:hover {
         background: darken($color: $pink, $amount: 7)
      }
   }

}

.button {
   width: 70px;
   text-align: center;
   font-size: 14px;
   transition: $transition;
   border-radius: 7px;
   cursor: pointer;
   padding: 8px 20px;
}
   
@media (max-width: 992px) {
   .editWalk {
      padding: 60px;
   }
}