@import '../../styles/variables.scss';

.walkShelter {
    border-radius: 18px;
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(88, 111, 138, 0.17);
    padding: 20px;
    position: relative;
    max-width: 600px;

    &__walker {
        display: flex;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
        align-items: flex-start;
    }

    &__walkerImg {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        overflow: hidden;
        flex: none;
        box-shadow: 0px 3.0696494579315186px 9.208949089050293px 0px rgba(88, 111, 138, 0.17);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__walkerInfo {
        margin-left: 20px;
        flex: 1;
        min-width: 200px;
    }

    &__walkerTime {
        display: flex;
        // grid-gap: 30px;
        flex-wrap: wrap;

        > div {
            margin-right: 30px;
        }
    }

    &__walkerName {
        font-weight: 500;
    }

    &__dog {
        display: flex;
        align-items: flex-start;
        margin-top: 20px;
        flex-wrap: wrap;
    }

    &__dogInfo {
        margin-left: 10px;
        margin-right: 40px;
    }

    &__dogMainInfo {
        display: flex;
        align-items: center;
    }

    &__dogName {
        font-size: 14px;
        font-weight: 500;
        margin-left: 10px;
    }

    &__dogId {
        margin-top: 10px;
        color: $lightGrey;
        font-size: 14px;
    }

    &__dogImg {
        width: 60px;
        height: 60px;
        border-radius: 7px;
        overflow: hidden;
        flex: none;
        box-shadow: 0px 3.0696494579315186px 9.208949089050293px 0px rgba(88, 111, 138, 0.17);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__dogButtons {
        display: flex;
        grid-gap: 10px;
        margin-left: auto;
    }
}