@import '../../../styles/variables.scss';
@import '../../../styles/commonStyles.scss';

.dogsPopup {
   position: relative;
   padding: 60px 100px 50px 100px;
   
   &__closeBtn {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
   }

   &__img {
      margin: 0 auto;
      display: block;
   }

   &__title {
      font-size: 24px;
      font-weight: 500;
      color: $darkBlue;
      text-align: center;
      margin-top: 24px;
   }

   &__plainText {
      color: #646775;
      font-size: 18px;
      text-align: center;
      margin-top: 16px;
   }

   &__btnWrapper {
      display: flex;
      justify-content: space-between;
      // width: fit-content;
      width: 250px;
      margin: 0 auto;
      margin-top: 24px;
   }

   &__btn {
      font-size: 14px;
      font-weight: 500;
      border-radius: 7px;
      cursor: pointer;
      transition: $transition;
   }
   
   &__emptyBtn {
      background: #fff;
      padding: 8px 0;
      text-align: center;
      border: 2px solid $pink;
      color: $pink;
      padding: 8px 0;
      text-align: center;
      width: 110px;

      &:hover {
         background: #ff8f8534;
      }
   }

   &__fullBtn {
      background: $pink;
      width: 110px;
      color: #fff;
      border: 2px solid transparent;
      padding: 8px 0;
      text-align: center;

      &:hover {
         background: darken($color: $pink, $amount: 7);
      }
   }

   &__leaveFeedbackBtn {
      background: $pink;
      padding: 8px 35px;
      color: #fff;
      width: fit-content;
      margin: 0 auto;
      margin-top: 24px;

      &:hover {
         background: darken($color: $pink, $amount: 7);
      }
   }
}