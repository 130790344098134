@import '../../styles/variables.scss';

.disabledDay {
   pointer-events: none;
   
   .react_time_range__track {
      display: none;
   }
   .react_time_range__handle_container {
      display: none;
   }
}

.timeline {

   .react_time_range__time_range_container {
      padding: 0;
      padding-top: 30px;
      width: 100%;
   }

   .react_time_range__track, .react_time_range__track__disabled, .react_time_range__rail__outer, .react_time_range__rail__inner{ 
      height: 33px;
   }

   .react_time_range__track {
      background-color: #a2fca08a !important;
   }

   .react_time_range__tick_marker, .react_time_range__tick_marker__large {
      margin-top: 12px;
   }

   .react_time_range__tick_marker__large {
      margin-top: 7px;
   }

   .react_time_range__rail__inner {
      border: 1px solid #e4e5e7;
      border-radius: 6px;
      background: transparent;
      z-index: 10;
   }
   
   .react_time_range__track__disabled {
      background: #f6f6f7;
      border: none;
   }

   &__errorText {
      position: absolute;
      top: 5px;
      left: 50%;
      transform: translateX(-50%);
      color: #cf3a3aa1;
      font-size: 12px;
   }

   &__availabilityDayError {
      @extend .timeline__errorText;
   }
}


.timeline--error {
   width: 100%;

   .react_time_range__time_range_container {
      padding: 0;
      padding-top: 30px;
      width: 100%;
   }

   .react_time_range__track, .react_time_range__track__disabled, .react_time_range__rail__outer, .react_time_range__rail__inner{ 
      height: 33px;
   }

   .react_time_range__track {
      background-color: #ff8f859a !important;
   }

   .react_time_range__tick_marker, .react_time_range__tick_marker__large {
      margin-top: 12px;
   }

   .react_time_range__tick_marker__large {
      margin-top: 7px;
   }

   .react_time_range__rail__inner {
      border: 1px solid #e4e5e7;
      border-radius: 6px;
      background: transparent;
      z-index: 10;
   }
   
   .react_time_range__track__disabled {
      background: #f6f6f7;
      border: none;
   }
}