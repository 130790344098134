@import '../../styles/variables.scss';

.btn {
    .MuiButton-label {
        letter-spacing: 0 !important;
    }
}

.btn--disabled {
    background: #eee !important;
    color: #ffffff !important;
    padding: 7px 15px !important;
    border: 1.5px solid transparent !important;
    border-radius: 7px !important;
    width: 100%;
    text-transform: none !important;
    white-space: nowrap;
    min-width: auto !important;
    pointer-events: none;
}


.outlinedBtn {
    border: 1.5px solid $pink !important;
    padding: 7px 15px !important;
    border-radius: 7px !important;
    color: $pink !important;
    transition: $transition !important;
    width: 100%;
    text-transform: none !important;
    white-space: nowrap;

    &:hover {
        background:  lighten($color: $pink, $amount: 22) !important;
     }

    .MuiTouchRipple-root {
        color: $pink !important;
        opacity: .7 !important;
     }
}

.transparentBlueBtn {
    background: #485a6b1d !important;
    padding: 7px 15px !important;
    border-radius: 7px !important;
    color: #485a6b !important;
    transition: $transition !important;
    width: 100%;
    text-transform: none !important;
    white-space: nowrap;

    &:hover {
        background: #485a6b23 !important;
     }
}

.plainBtn {
    background: $pink !important;
    color: #ffffff !important;
    padding: 7px 15px !important;
    border: 1.5px solid transparent !important;
    border-radius: 7px !important;
    transition: $transition;
    width: 100%;
    text-transform: none !important;
    white-space: nowrap;
    min-width: auto !important;

    &:hover {
       background: darken($color: $pink, $amount: 7) !important;
    }
}

.full {
    width: 100%;
}

.lightButton {
    background: lighten($color: $pink, $amount: 20) !important;
    color: $pink !important;
    padding: 7px 15px !important;
    border: 1.5px solid transparent !important;
    border-radius: 7px !important;
    transition: $transition;
    width: 100%;
    text-transform: none !important;
    white-space: nowrap;
    min-width: auto !important;

    &:hover {
        background: lighten($color: $pink, $amount: 18) !important;
    }
}