@import '../../styles/variables.scss';
@import '../../styles/commonStyles.scss';


.search {

   &__locationLabel {
      left: 30px !important;
   }

   &__label.Mui-focused {
      color: $pink !important;
   }

   &__locationInput {
      box-shadow: 2px 2px 8px rgba(32, 33, 65, 0.10);
      width: 250px !important;
      margin: 0 auto;
      border-radius: 8px !important;
      text-align: left;

      input {
         padding-left: 40px !important;
      }

      label {
         padding-left: 30px;
      }

      fieldset {
         border: none !important;

         &::before {
            content: url('../../../public/images/ico/catsPaw.svg');
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
         }
      }
   }

   &__noWalks {
      margin: 0 auto;
      font-size: 16px;
      color: $lightGrey;
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      & > div {
         margin-top: 10px;
      }
   }

   &__filter {
      box-shadow: 2px 2px 8px rgba(32, 33, 65, 0.10);
      width: 150px;
      margin: 0 auto;
      border-radius: 8px !important;
      text-align: left;

      &>fieldset {
         border: none !important;
      }

   }

   /* Switcher Styles */

   &__switcher {
      display: inline-block;
      height: 34px;
      margin-top: 3px;
      padding: 4px;
      border-radius: 8px;
      width: 200px;
      position: relative;
      background: lighten($color: $pink, $amount: 20);
   }

   &__switcherInput {
      display: none;
   }

   &__switcherLabel {
      float: left;
      width: 50%;
      font-size: 14px;
      line-height: 34px;
      color: $pink;
      text-align: center;
      cursor: pointer;
      position: inherit;
      z-index: 10;
      transition: color 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
      will-change: transform;
   }

   &__switcherToggle {
      position: absolute;
      float: left;
      height: 34px;
      width: 50%;
      font-size: 12px;
      line-height: 30px;
      // cursor: pointer;
      border-radius: 8px;
      left: 5px;
      top: 4px;
      transition: left 0.25s cubic-bezier(0.4, 0.0, 0.2, 1);
      will-change: transform;
      background: $pink;
   }

   &__switcherInput:checked+.search__switcherLabel {
      color: #fff;
      pointer-events: none !important;
   }

   &__switcherInput--shelters:checked~.search__switcherToggle {
      left: 100px !important;
   }

   // mobile stitcher

   &__switcherMobile {
      display: none;
      height: 34px;
      margin-top: 3px;
      padding: 4px;
      border-radius: 8px;
      width: 200px;
      position: relative;
      background: lighten($color: $pink, $amount: 20);
   }

   // walks

   &__walks {
      width: 1125px;
      max-width: 90%;
      margin: 0 auto;
      margin-top: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid #eaeaea;

      &:has(.search__noWalks) {
         border-bottom: 1px solid transparent;
      }

   }

   &__walksWrapper {
      margin-top: 40px;
      display: grid;
      grid-gap: 40px;
      grid-template-columns: repeat(auto-fit, 300px);
   }


   &__walksShelterWrapper {
      margin-top: 40px;
      display: grid;
      grid-gap: 40px;
      grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
   }



   &__selectedShelter {
      border-radius: 20px;
      background: #F6F6F9;
      display: flex;
      align-items: center;
      padding: 2px 10px 2px 20px;
      color: $darkBlue;
      font-size: 14px;

      img {
         width: 12px;
         padding: 10px;
         cursor: pointer;
      }
   }




   &__wrapper {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      // border: 1px solid red;
   }

   &__filterWrapper {
      display: flex;
      width: 1125px;
      margin: 0 auto;
      margin-top: 40px;
      position: relative;
      max-width: 90%;
      align-items: flex-start;
      justify-content: space-between;
      grid-gap: 15px;
      flex-wrap: wrap;
      align-items: center;

      &>div {
         display: flex;
         align-items: center;
      }

   }

   &__filterMainInfoWrapper {
      grid-gap: 15px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
   }

   &__autocompleteWrapper {
      z-index: 3;
      overflow: hidden;
      box-shadow: 2px 2px 8px rgba(32, 33, 65, 0.10);
      width: 480px;
      margin: 0 auto;
      border-radius: 8px;
   }

   &__autocompleteWrapper--error {
      @extend .search__autocompleteWrapper;
      border: 1px solid rgba(245, 57, 57, 0.46);
      box-shadow: 2px 2px 8px rgba(209, 15, 15, 0.207);
   }


   &__placeInputWrapper {
      position: relative;

      input {
         &::placeholder {
            color: $darkBlue;
         }
      }
   }

   &__placeInputImg {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
   }

   &__placeInputArrow {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      color: $pink;
   }

   &__placeInput {
      box-sizing: border-box;
      padding: 12px 50px 12px 40px;
      outline: none;
      width: 100%;
      border: none;
      font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 14px;
   }

   &__suggestions {
      z-index: 1;
      background: #ffffff;
      position: absolute;
      border-radius: 0px 0px 8px 8px;
      width: 480px;
      box-sizing: border-box;
      box-shadow: 0px 5px 8px 0px rgba($color: $darkGrey, $alpha: .1);
   }

   &__places {
      margin: 0 auto;
      text-align: start;
      padding: 3px 20px;
      cursor: pointer;
      font-size: 14px;

      &:last-child {
         border-radius: 0px 0px 8px 8px;
         padding: 3px 20px 10px 20px;
      }
   }

   &__places--active {
      @extend .search__places;
      background: $pink;
      color: #ffffff;
   }

   &__dateWrapper {
      background: #ffffff;
      box-shadow: 2px 2px 8px rgba(32, 33, 65, 0.12);
      border-radius: 8px;
   }

   &__dateBtn {
      width: 115px;
      padding: 0px 0px 0px 14px !important;
      margin: 6px 0 !important;
      border-radius: 8px;

      input {
         font-size: 14px;
      }
   }

   &__orderBtn {
      font-weight: 500;
      color: #ffffff;
      font-size: 14px;
      margin: 0 auto;
      margin-top: 24px;
      width: fit-content;
      border-radius: 7px;
      background: $pink;
      padding: 8px 25px;
      transition: $transition;
      cursor: pointer;
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 103;
      -webkit-box-shadow: 0px 0px 14px 6px rgba(255, 255, 255, 0.7);
      -moz-box-shadow: 0px 0px 14px 6px rgba(255, 255, 255, 0.7);
      box-shadow: 0px 0px 14px 6px rgba(255, 255, 255, 0.7);

      &:hover {
         background: darken($color: $pink, $amount: 7);
      }
   }
}

.dogs {
   width: 1125px;
   max-width: 90%;
   margin: 0 auto;
   margin-top: 40px;
   margin-bottom: 40px;
   display: grid;
   grid-template-columns: repeat(auto-fit, 265px);
   grid-gap: 20px;
}

.dogBox {
   background: #ffffff;
   border-radius: 20px;
   padding: 18px 18px 35px 18px;
   box-sizing: border-box;
   box-shadow: 2px 2px 12px rgba($color: $darkGrey, $alpha: .2);
   position: relative;
   margin-bottom: 20px;

   &__imgWrapper {
      border-radius: 10px;
      width: 70px;
      height: 65px;
      overflow: hidden;
      position: relative;
      margin: 0 auto;
      box-shadow: 0px 2.138852596282959px 6.416557788848877px 0px rgba(88, 111, 138, 0.17);

      img {
         height: 100%;
         width: 100%;
         object-fit: cover;
      }
   }

   &__info {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: center;
      margin-top: 15px;
   }

   &__name {
      color: $darkBlue;
      text-align: start;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-left: 10px;
   }

   &__rate {
      font-size: 13px;
      text-align: start;
      color: $darkGrey;
   }

   &__desc {
      margin-top: 20px;
      font-size: 14px;
      text-align: start;
   }

   &__descLabel {
      margin-top: 10px;
      color: $lightGrey;
   }

   &__descText {
      color: $darkBlue;
   }

   &__rating {
      flex: 1;
      padding: 0 30px 0 10px;
      box-sizing: border-box;
   }

   &__ratingText {
      font-size: 13px;
      color: $darkGrey;
   }

   &__ratingValue {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $darkBlue;
      margin-top: 0;
      display: flex;
      align-items: center;
   }

   &__ratingValueIcon {
      margin-right: 5px;
      color: $pink;
      font-size: 2px;
   }

   &__walks {
      flex: 1;
      padding: 0 10px 0 30px;
      box-sizing: border-box;
      border-left: 1px solid #f2f2f3;
   }

   &__walksText {
      font-size: 13px;
      color: $darkGrey;
   }

   &__walksValue {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      margin-top: 0;
      color: $darkBlue;
   }

   &__orderBtn {
      background: $pink;
      transition: $transition;
      border-radius: 8px;
      color: #ffffff;
      padding: 7px 20px;
      white-space: nowrap;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      width: fit-content;
      position: absolute;
      bottom: -17px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;

      a {
         color: #fff;
      }

      &:hover {
         background: darken($color: $pink, $amount: 7);
      }
   }

   &__orderBtn--disabled {
      @extend .dogBox__orderBtn;
      background: #dddddd;
      cursor: pointer;
      
      &:hover {
         background: #dddddd;
      }
   }


}

// map

.mainMapShelter {
   height: 600px;
   width: 100%;
   margin-bottom: 100px;
}

.mapBtn {
   border-radius: 20px;
   background: lighten($color: $pink, $amount: 10);
   color: #fff;
   padding: 5px 20px;
   position: absolute;
   font-size: 14px;
   top: -20px;
   left: 50%;
   z-index: 103;
   transform: translateX(-50%);
}

.mainMapShelter--expaned {
   height: 100%;
   flex: 1;
   position: absolute;
   top: 0;
   left: 0;
}

.mapCircleWrapper {
   height: calc(100vh - 100px);
   z-index: 100;
   overflow: hidden;
}

.mapCircleWrapper:has(.mapCircle--fullScreen) {
   position: absolute;
   width: 100%;
 }


.mapCircle {
   height: 170px;
   width: 170px;
   position: fixed;
   border-radius: 50%;
   bottom: 150px;
   right: 80px;
   z-index: 102;
   overflow: hidden;
   transition: all .2s ease-out;
   transform: scale(1);
   transform-origin: 0 100%;
   box-sizing: border-box;
   border: 3px solid transparent;
   outline: 4px solid #eee;


   &__walksCount {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: $pink;
      color: #fff;
      width: 40px;
      height: 40px;
      bottom: 280px;
      z-index: 103;
      right: 80px;
      position: absolute;
      position: fixed;
      font-weight: 500;
      // display: none;
   }

   &--fullScreen {
      @extend .mapCircle;
      animation: circle .4s ease-in-out;
      animation-fill-mode: forwards;
   }
}






.mapWrapper {
   width: 100%;
   height: 100%;
}


@media (max-width: 769px) {

   .search__wrapper .search__switcher{
         display: none !important;
      }

   .search {

      &__switcherMobile {
         display: flex;
         margin: 0 auto;
         margin-top: 10px;
      }

      &__filterWrapper {
         flex-wrap: wrap;
         margin-top: 15px;
      }

      &__filterMainInfoWrapper {
         width: max-content;
         flex-wrap: wrap-reverse;
      }
   }
}

@media (max-width: 540px) {
   .search {
      &__filterMainInfoWrapper {
         width: 100%;
         margin: 0 8px;

         >div {
            width: 100%;
         }
      }

      &__filterWrapper {
         margin-top: 10px;
      }

      &__selectedShelter {
         margin: 0 auto;

         img {
            margin-left: auto;
         }
      }

      &__locationInput {
         width: 100% !important;
         // margin: 0 8px !important;
      }

      &__sizeFilterWrapper {
         width: 100%;

         &>div {
            width: 100%;
         }
      }

      &__filter {
         width: 100%;
      }

      &__switcherMobile {
         width: 300px;
         margin: 0 auto;
         margin-bottom: 20px;
      }

      &__switcherInput--shelters:checked~.search__switcherToggle {
         left: 150px !important;
      }
   }

   .dogs {
      grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
   }
}


@keyframes circle {
   0% {
      height: 150px;
      width: 150px;
      bottom: 150px;
      right: 100px;
      border-radius: 50%;
   }

   0% {
      height: 150px;
      width: 150px;
      bottom: 150px;
      right: 100px;
      border-radius: 100px;
   }

   100% {
      height: 100%;
      width: 100%;
      bottom: 0px;
      right: 0px;
      border-radius: 0px;
      opacity: 1;
      position: absolute;
   }
}