.locationPopup {
    text-align: center;
    margin: 50px 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &__img {
        width: 80px;

        img {
            width: 100%;
        }
    }

    &__content {
        width: 400px;
    }
    
    &__btn {
        margin-top: 20px;
    }
}