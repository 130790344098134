@import '../../styles/variables.scss';

.dogsSection {
   margin: 75px 0;

   &__menu {
      display: flex;
      grid-gap: 20px;
      justify-content: flex-start;
      width: 1250px;
      max-width: 90%;
      margin: 0 auto;
      margin-top: 30px;
      text-align: center;
   }
   
      &__menuItem {
         font-size: 14px;
         padding: 8px 20px;
         border: 1px solid lighten($color: $pink, $amount: 15);
         color: $pink;
         border-radius: 7px;
         width: 100px;
         cursor: pointer;
         transition: $transition;
         margin-top: 70px;
         
         &:hover {
            background:  lighten($color: $pink, $amount: 20);
         }
      }
   
      &__menuItem--active {
         @extend .dogsSection__menuItem;
         background: $pink;
         color: #fff;
         border: 1px solid $pink;
         pointer-events: none;
      }
}

.dogs__wrapper {
   width: 800px;
   max-width: 90%;
   margin: 0 auto;
   position: relative;
   border: 1px solid transparent;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   // height: max-content;
}

.dogs__wrapper--expanded {
   @extend .dogs__wrapper;
   width: 950px !important;
   height: auto;
}


.dog {

   &__empty {
      position: relative;

      &::after {
         content: "";
         position: absolute;
         top: -30px;
         left: -20px;
         width: 110%;
         height: 120%;
         border: none;
         background: rgba(255, 255, 255, 0.801);
      }

      .dog__imgWrapper {
         top: -20px;
      }
   }


   &__imgWrapper {
      border-radius: 18px;
      position: absolute;
      top: 0;
      left: 0;
      width: 220px;
      height: 170px;
      overflow: hidden;
      background: #FFFFFF;
      box-shadow: 4px 4px 12px rgba(85, 106, 125, 0.25);

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &__imgWrapper--empty {
      @extend .dog__imgWrapper;
   }

   &__info {
      width: 295px;
      margin: 10px 0 0 40px;
      box-sizing: border-box;
      padding: 25px;
      border-radius: 18px;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(88, 111, 138, 0.17);
   }

   &__mainInfo {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      width: 70px;
      align-items: center;
   }
   
   &__infoText {
      font-size: 11px;
      text-transform: capitalize;
      color: $darkGrey; 
   }
   
   &__infoValue {
      display: flex;
      margin-top: 5px;
      color: $darkBlue;
      font-size: 13px;
      
      img {
         margin-right: 5px;
      }
   }
   
   &__infoLine {
      width: 100%;
      height: 1px;
      background: #e0e1e4;
      margin: 5px 0;
   }

   &__aboutWrapper {
      text-align: start;
   }
   
   &__name {
      color: $darkBlue;
      font-size: 16px;
      font-weight: 500;
      margin-top: 0;
      text-transform: capitalize;
   }

   &__commentLabel {
      color: $darkBlue;
      font-size: 14px;
      font-weight: 400;
      margin-top: 10px;
   }

   &__comment {
      font-size: 12px;
      margin-top: 5px;
      color: #767985;
      line-height: 20px;
   }
}

//my dogs

.myDogs {
   margin: 0 30px;
   margin-top: 50px;

   &__innerWrapper {
      width: 360px;
      height: 190px;
      position: relative;
      margin: 0 auto;
   }

   &__dogWrapper {
      position: absolute;

      &:hover > .myDogs__dogButtons {
         opacity: 1;
      }
   }

   &__dog {
      width: 88px;
      height: 88px;
      border-radius: 50%;
   }

   &__dog.MuiButton-contained {
      border-radius: 50%;
   }
   
   &__dogImgWrapper {
      width: 88px;
      box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.12);
      height: 88px;
      border-radius: 50%;
      position: absolute;
      overflow: hidden;
      z-index: 20;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         position: relative;
      }
   }

   &__dogButtons {
      z-index: 100;
      display: flex;
      opacity: 0;
      transition: $transition;
      position: absolute;
      bottom: -15px;
      width: 100%;
      justify-content: space-evenly;
   }

   &__dogBtn {
      border-radius: 50%;
      width: 26px;
      height: 26px;
      position: relative;
      box-shadow: 1px 1px 6px rgba(32, 33, 65, 0.1);
      cursor: pointer;
      border: 2px solid #fff;
      transition: $transition;
   }

   &__dogEditBtn {
      @extend .myDogs__dogBtn;
      background: rgb(231, 236, 245);

      &:hover {
         background: rgb(73, 111, 184);
      }

      &:hover .myDogs__dogIcon.MuiSvgIcon-root {
         color: #fff;
       }

      .myDogs__dogIcon.MuiSvgIcon-root {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         font-size: 15px;
         color: rgb(73, 111, 184);
         transition: $transition;
      }
   }

   &__dogRemoveBtn {
      @extend .myDogs__dogBtn;
      background: rgb(248, 228, 226);

      &:hover {
         background: rgb(218, 88, 76);
      }

      &:hover .myDogs__dogIcon.MuiSvgIcon-root {
        color: #fff;
      }

      .myDogs__dogIcon.MuiSvgIcon-root {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
         font-size: 18px;
         color:rgb(218, 88, 76);
         transition: $transition;
      }
   }
   
   &__dog--1 {
      bottom: 10%;
      left: 0;
      background: #f6f6f6;
      @extend .myDogs__dog;
   }
   
   &__dog--2 {
      top: 0;
      left: 22%;
      background: #f6f6f6;
      @extend .myDogs__dog;
      animation: dog2--animation .3s linear 0s 1;
   }

   &__dog--3 {
      top: 0;
      right: 22%;
      background: #f6f6f6;
      @extend .myDogs__dog;
      animation: dog3--animation .4s linear 0s 1;
   }
   
   &__dog--4 {
      bottom: 10%;
      right: 0;
      background: #f6f6f6;
      @extend .myDogs__dog;
      animation: dog4--animation .5s linear 0s 1;
   }

   &__addBtn {
      position: absolute;
      width: 44px;
      height: 44px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      background: #fff;
      box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.12);
      border-radius: 50%;
      cursor: pointer;
      z-index: 10;
      transition: $transition;
      border: 1px solid transparent;
      
      &:hover {
         border: 1px solid $pink;
      }
      
      img {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
   }

   &__informText {
      color: rgb(112, 112, 112);
      font-size: 12px;
      margin-top: 5px;
      text-align: center;
      padding: 0 5px;
   }

   &__informAddDogText {
      color: $darkBlue;
      font-size: 16px;
      position: absolute;
      text-align: center;
      width: 100%;
      left: 50%;
      top: 220px;
      transform: translateX(-50%);
   }

   &__informMuchDogs {
      color: rgb(201, 201, 201);
      width: 150px;
      font-size: 12px;
      position: absolute;
      left: 50%;
      bottom: 10%;
      transform: translateX(-50%);
   }

   &__searchBtn {
      padding: 10px 30px;
      color: #ffffff;
      display: flex;
      align-items: center;
      background: $pink;
      transition: $transition;
      width: fit-content;
      border-radius: 7px;
      font-size: 14px;
      font-weight: 500;
      margin: 0 auto;
      margin-top: 40px;

      img {
         margin-right: 10px;
      }

      &:hover {
         background: darken($color: $pink, $amount: 7);
      }
   }

}


// my dogs list, when dogs length > 4
.myDogsListWrapper {
   flex: 1;
   margin-left: 30px;
}

.myDogsList {
   height: 340px;
   overflow-y: auto;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-content: flex-start;
   grid-gap: 15px;
   padding: 0 20px;
   margin-top: 20px;

   &::-webkit-scrollbar-track {
      background-color: #e7ecf5;
      // background-color: lighten($color: $pink, $amount: 20) !important;
      border-radius: 5px;
  }

  &::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
      background-color: #e7ecf5;
      border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #486fb85a !important;
      border-radius: 5px;
      cursor: pointer !important;
      width: 5px;
      height: 5px !important;

      &:hover {
          background-color: #486fb88e !important;
      }
  }


   &__mainInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
   }

   &__title {
      font-weight: 500;
      font-size: 18px;
      padding: 0 20px;
   }

   &__addBtn {
      width: 44px;
      height: 44px;
      background: #fff;
      box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.12);
      border-radius: 50%;
      cursor: pointer;
      z-index: 10;
      transition: $transition;
      border: 1px solid transparent;
      position: relative;
      margin: 0 20px;
      
      &:hover {
         border: 1px solid $pink;
      }
      
      img {
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
   }

   &__dogWrapper {
      border: 1px solid #eee !important;
      width: 48% !important;
      border-radius: 5px !important;
      box-shadow: 0px 4px 7px rgba(88, 111, 138, 0.10) !important;
      background: #fff !important;
      padding: 10px !important;
      box-sizing: border-box !important;
      cursor: pointer !important;
      transition: .2s ease-in-out !important;
      text-transform: none !important;
      
      .MuiTouchRipple-root {
         color: rgb(149, 167, 197) !important;
         opacity: .7 !important;
         z-index: 20;
      }

      &:hover {
         border: 1px solid lighten($color: $pink, $amount: 10) !important;
      }
   }

   &__dog {
      display: flex;
      width: 100%;
      height: fit-content;
   }

   &__dogInfoWrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-left: 10px;
   }

   &__dogInfo {
      display: flex;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
   }

   &__dogImgWrapper {
      width: 75px;
      border-radius: 5px;
      overflow: hidden;
      flex: none;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &__dogNameWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
   }

   &__dogName {
      font-weight: 500;
      margin-right: 10px;
   }

   &__dogSize {
      margin-top: 5px;
   }

   &__age {
      margin-top: 10px;
      font-size: 14px;
      width: fit-content;
   }

   &__buttons { 
      display: flex;
      flex-direction: column;
      grid-gap: 5px;
   }
}

// last walk

.walks {

   &__walkTitle {
      color: $darkBlue;
      font-size: 22px;
      font-weight: 500;
      margin-top: 40px;
      text-align: center;
   }

   &__tabsWrapper {
      width: 1250px;
      max-width: 90%;
      margin: 0 auto;
      padding: 24px 0;
      margin-top: 30px;

      header {
         box-shadow: none;
      }

      button.Mui-selected {
         color: #ff9085;
      }
   }
}

.lastWalk {
   margin-top: 40px;
   text-align: start;
   display: grid;
   grid-template-columns: repeat(auto-fit, 500px);
   justify-content: center;
   grid-gap: 20px;
   margin: 0 auto;
   margin-top: 30px;

   &__container {

   } 

   &__noWalkMessage {
      font-size: 18px;
      text-align: center;
      font-style: italic;
      color: rgb(172, 172, 172);
   }
   
   &__box {
      background: #ffffff;
      color: $darkBlue;
      width: fit-content;
      box-shadow: 0px 4px 12px rgba(88, 111, 138, 0.17);
      border-radius: 24px;
      display: flex;
      align-items: center;
      padding: 10px 15px 10px 10px;
      margin: 0 auto;
   }
   
   &__walkerImg {
      width: 77px;
      height: 77px;
      overflow: hidden;
      border-radius: 18px;
      
      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }
   
   &__desc {
      display: flex;
      flex-direction: column;
      margin: 0 40px 0 20px;
      max-width: fit-content;
      min-width: 200px;
   }
   
   &__walkerName {
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;
      
      display: flex;
      align-items: center;
   }
   
   &__walkStatus {
      font-size: 10px;
      font-weight: 600;
      text-decoration: underline;
      color: #2174ac;
      text-transform: uppercase;
      margin-left: 10px;
   }
   
   &__walkDetails {
      text-align: start;
      font-size: 12px;
      font-style: italic;
      color: rgb(123, 123, 123);
   }
   
   
   &__info {
      display: flex;
      margin-top: 5px;
   }
   
   &__infoBox {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      align-items: flex-start;
   }

   &__label {
      text-transform: capitalize;
      font-size: 12px;
      color: #83858f;
      font-weight: 400;
   }

   &__date {
      color: $darkBlue;
      font-size: 14px;
      margin-top: 2px;
   }

   &__time {
      @extend .lastWalk__date;
   }

   &__orderBtn {
      padding: 8px 19px;
      text-align: center;
      color: #fff;
      background: $pink;
      font-size: 14px;
      font-weight: 500;
      border-radius: 8px;
      cursor: pointer;
      transition: $transition;
      white-space: nowrap;

      &:hover {
         background: darken($color: $pink, $amount: 7)
      }
   }

   &__seeMoreBtn {
      color: $pink;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      border-bottom: 1px solid $pink;
      width: fit-content;
      line-height: 20px;
      margin-left: 200px;
      margin-top: 25px;
      cursor: pointer;
      transition: $transition;
      position: relative;

      &:hover {
         color: #ffffff;
         background: $pink;
         padding: 0 5px;
      }
   }
}

@media (max-width: 1000px) {
   .myDogsList {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));

      &__dogWrapper {
         width: auto !important;
      }

      &__dog {
         flex-direction: column;
      }

      &__dogInfoWrapper {
         flex-direction: column;
      }

      &__dogImgWrapper {
         width: 100%;
         height: 80px;
      }

      &__buttons {
         flex-direction: row;
         margin-top: 10px;
         margin-left: auto;
         margin-right: 10px;
      }

      &__dogName {
         margin-top: 5px;
      }
   }
}


@media (max-width: 840px) {

   .dogs__wrapper {
      flex-direction: column;
      align-content: center;
   }

   .dog {
      position: relative;
      width: fit-content;
      margin: 0 auto;

      &__imgWrapper {
         position: absolute;
         top: 0;
         left: 0;
      }
   }

   .myDogs {
      margin-top: 50px;
   }

   .myDogsListWrapper {
      margin-top: 30px;
      width: 100%;
      margin-left: 0;
   }

    .dogsSection {
      margin-top: 20px;
   }
}

@media (max-width: 460px) {
   .myDogsList {
      &__dog {
         flex-direction: row;
      }

      &__dogInfoWrapper {
         flex-direction: row;
      }

      &__dogImgWrapper {
         width: 80px;
         height: auto;
      }

      &__buttons {
         flex-direction: column;
         margin-top: 0;
         margin-right: 0;
      }

      &__dogName {
         margin-top: 5px;
      }
   }
   .dogsSection {
      &__menu {
         justify-content: center;
      }
   }

}
@keyframes dog2--animation {
   0%   {
      top: 70px; 
      left: 0px;
   }
   
   50%  {
      top: 10px; 
      left: 10%;
   }

   100% { 
      top: 0; 
      left:22%;
      opacity: 1;
   }
 }

 @keyframes dog3--animation {
   0%   {
      top: 70px; 
      right: 90%;
   }
   
   50%  {
      top: 10px;
      right: 50%;
   }

   100% { 
      top: 0;
      right: 22%;
      opacity: 1;
   }
 }

 @keyframes dog4--animation {
   0%   {
      bottom: 10%;
      right: 90%;
   }

   25% {
      bottom: 40%;
      right: 60%;
   }
   
   50%  {
      bottom: 60%;
      right: 40%;
   }

   75% {
      bottom: 40%;
      right: 20%;
   }

   100% { 
      bottom: 10%;
      right: 0;
      opacity: 1;
   }
 }