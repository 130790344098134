@import '../../styles/variables.scss';
@import '../../styles/commonStyles.scss';

.timeSelection {
    &__timeBadges {
        display: flex;
        grid-gap: 16px;
        flex-wrap: wrap;
        margin-top: 10px;
     }
  
     &__timeBadge {
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 8px;
        box-shadow: 2px 2px 6px 0px rgba(48, 61, 86, 0.15);
        background: #fff;
        cursor: pointer;
        border: 1px solid transparent;
     }
  
     &__timeBadge--active {
        @extend .timeSelection__timeBadge;
        border: 1px solid lighten($color: $pink, $amount: 10);
        color: darken($color: $pink, $amount: 5);
        box-shadow: 2px 2px 6px 0px lighten($color: $pink, $amount: 15);
     }

    &__input {
        box-shadow: 2px 2px 8px rgba(32, 33, 65, 0.10);
         width: 150px;
        border-radius: 8px !important;
        margin-top: 5px;

    & > div {
        padding: 12px 8px 8px 8px;
    }

      &>fieldset {
         border: none !important;
         line-height: 30px !important;
      }
    }

    .profile__confirmationLabel:nth-child(3) {
        margin-top: 15px;
    }
}