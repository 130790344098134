@import '../../styles/variables.scss';

.manageTimeSection {
    width: 1250px;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 50px;

    &__title {
        color: $darkBlue;
        font-size: 22px;
        font-weight: 500;
        text-align: center;
    }

    &__dogs {
        margin-top: 50px;
        width: 100%;
        height: 140px;
        display: flex;
        grid-gap: 20px;
    }

    &__dogs--scrollable {
        display: flex;
        grid-gap: 20px;
        overflow-x: auto;
        padding: 0 10px;

        &::-webkit-scrollbar-track {
            background-color: #e7ecf5;
            border-radius: 5px;
        }

        &::-webkit-scrollbar {
            height: 5px !important;
            background-color: #e7ecf5;
            border-radius: 5px;

        }

        &::-webkit-scrollbar-thumb {
            background-color: #486fb85a !important;
            border-radius: 5px;
            cursor: pointer !important;

            &:hover {
                background-color: #486fb88e !important;
            }
        }
    }

    &__dog {
        border-radius: 18px;
        width: 190px;
        overflow: hidden;
        background: #FFFFFF;
        box-shadow: 0px 4px 12px rgba(88, 111, 138, 0.10);
        position: relative;
        cursor: pointer;
        border: 2px solid transparent;
        outline: 3px solid transparent;
        transition: $transition;
        box-sizing: border-box;
        min-width: 150px;
        margin: 15px 0;

        &:hover {
            outline: 4px solid lighten($color: $pink, $amount: 15);
            border: 3px solid transparent;

        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__dog--allDogs {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        outline: 4px solid transparent;
        border: 2px solid transparent;
    }

    &__allDogsTitle {
        font-weight: 500;
        text-align: center;
        font-size: 18px;
        color: $pink;
    }

    &__dog--active {
        outline: 4px solid lighten($color: $pink, $amount: 10);
        border: 2px solid #fff;
        pointer-events: none;
    }


    &__dogName {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #3030305f;
        color: #fff;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        padding: 3px;
        backdrop-filter: blur(3px);
    }

    // SCHEDULE

    &__scheduleWrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        grid-gap: 100px;


        .MuiFormControlLabel-root {
            margin-right: 0 !important;

            span {
                color: $pink;
            }

            svg {
                fill: $pink !important;
            }
        }
    }

    &__schedule {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 400px;
    }

    &__scheduleTitle {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 40px;
    }

    &__day {
        margin-bottom: 30px;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }

    &__dayNameWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__dayName {
        font-weight: 500;
        font-size: 18px;
        color: $darkGrey;
        width: 120px;
        text-align: start;
        margin-right: 20px;
    }

    &__dayName--weekend {
        @extend .manageTimeSection__dayName;
        color: rgb(213, 82, 70);
    }


    &__timeWrapper {
        margin-top: 10px;
    }
    
    &__time {
        display: flex;
        max-width: 400px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start
    }

    &__time--disabled {
        @extend .manageTimeSection__time;
        opacity: .3;
        pointer-events: none;

    }

    &__timeInput {
        margin-right: 20px !important;
        width: 90px !important;

        input {
            padding: 10px !important;
        }

        button {
            padding: 5px !important;
        }
        
        .MuiInputAdornment-root {
            margin-left: -7px;
        }
    }

    &__addBreakSection {
        display: flex;
        align-items: flex-start;
    }


    &__breaksWrapper {
        display: flex;
        flex-direction: column;
    }
    
    &__breakWrapper {
        padding: 15px 10px;
        border: 1px solid lighten($color: $pink, $amount: 10);
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        margin-bottom: 10px;
        position: relative;

        .divider {
            margin: 0 10px;
        }

        .manageTimeSection__timeInput {
            margin-right: 0 !important;
        }
    }

    &__addBreakBtn {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 10px 20px;
        border: 1px solid lighten($color: $pink, $amount: 15);
        color: $pink;
        border-radius: 3px;
        width: 100px;
        cursor: pointer;
        transition: $transition;

        &:hover {
            background: lighten($color: $pink, $amount: 20);
        }


        div {
            margin-left: 10px;
        }
    }

    //  specific settings 

    &__specificSettings {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 370px;
        margin-bottom: 100px;
    }

    &__scheduleDay {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-right: 20px;

        input {
            padding: 10px !important;
            width: 100px;
        }
    }

    &__saveBtn {
        width: 150px;
        // position: sticky;
        // bottom: 20px;
    }

    &__breakDeleteBtn {
        position: absolute;
        top: -11px;
        left: -17px;
        background: #fff;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        box-shadow: 1px 1px 6px rgba(32, 33, 65, 0.1);
        cursor: pointer;
        border: 2px solid #fff;
        transition: $transition;
        background: rgb(248, 228, 226);

        &:hover {
            background: rgb(218, 88, 76);
        }
        &:hover > .manageTimeSection__breakDeleteIcon {
            color: #FFFFFF;
        }
    }

    &__breakDeleteIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgb(218, 88, 76);
        font-size: 20px !important;
    }

    &__specificDatesWrapper {
        margin-top: 50px;
        width: 100%;
    }
    
    &__specificDate {
        background: #FFFFFF;
        box-shadow: 0px 4px 12px rgba(88, 111, 138, 0.17);
        border-radius: 18px;
        display: flex;
        padding: 15px;
        justify-content: space-between;
        font-size: 14px;
        margin-bottom: 20px;
        align-items: center;
        position: relative;

        .userProfile__walkLabel {
            margin-top: 0;
        }
    } 

    &__specificDateDeleteBtn {
        border: 1px solid $pink;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 10px;
        cursor: pointer;
        transition: $transition;
        color: $pink;
        padding: 7px;
    
        &:hover {
            background: lighten($color: $pink, $amount: 20);
        }
    }

    &__dayOffBadge{
        width: 50px;
        height: 25px;
        background-color: $lightBlue;
        position: absolute;
        font-size: 12px;
        left: 120px;
        top: 0;
        padding: 10px 5px 0 5px;
        color: $darkBlue;
        font-weight: 500;
        animation:drop forwards .8s 1s cubic-bezier(0.165, 0.84, 0.44, 1);
        
        &:before{
            content: '';
            position: absolute;
            z-index: 2;
            left: 0;
            bottom: -15px;
            border-left: 30px solid $lightBlue;
            border-right: 30px solid $lightBlue;
            border-bottom: 15px solid transparent;
        }
    }

    &__loader {
        

    }

}


@media (max-width: 960px) {
    .manageTimeSection {
        &__scheduleWrapper {
            flex-direction: column;
        }

        &__schedule {
            margin-bottom: 0;
        }
    }
}

    @media (max-width: 860px) {
    .manageTimeSection {
        &__dogs {
            display: flex;
            flex-direction: column;
            height: auto;
            grid-gap: 0;
        }

        &__dogs--scrollable {
            grid-gap: 10px;
        }

        &__dog {
            height: 80px;
            border-radius: 5px;
            min-width: 110px;
            border: 2px solid transparent;
            outline: 2px solid transparent;

            &:hover {
                outline: 2px solid lighten($color: $pink, $amount: 12);
                border: 2px solid #fff;
            }
        }

        &__dog--allDogs {
            height: 40px;
            outline: 1px solid #fdcece;
            border: 2px solid transparent;
            width: 100%;
        }

        &__dog--allDogs.manageTimeSection__dog--active {
            background: lighten($color: $pink, $amount: 22);
        }


        &__allDogsTitle {
            font-size: 14px;
        }

        &__dog--active {
            outline: 2px solid lighten($color: $pink, $amount: 2);
            border: 2px solid #fff;
            pointer-events: none;
        }
    }
}

@media (max-width: 460px) {
    .manageTimeSection {
        &__timeInput {
            width: 70px !important;
        }

        &__addBreakBtn {
            padding: 10px
        }
        
        &__day {
            width: 100%;
        }

        &__saveBtn {
            width: 100%;
        }

        &__specificSettings {
            width: 100%;
        }
    }
}