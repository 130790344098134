@import '../../styles/variables.scss';

.timelineWrapper {
   
}

.timelineOverlay {
   position: absolute;
   left: 0;
   top: 37px;
   width: 100%;
   height: 34px;
   z-index: 15;
   cursor: pointer;
   transition: $transition;
   border-radius: 7px;
   backdrop-filter: grayscale(1);
   background:  #eaeef19f;
   border: 1px solid  #c1ccd6;
                     
   &:hover {
      backdrop-filter: grayscale(0.4);
      background:  #eaeef15d;
      border: 1px solid  #a9bfd354;
   }
}

.timelineOverlayError {
   @extend .timelineOverlay;
   background: #eeeeee15;
   cursor: not-allowed;

   &:hover {
      background: #eeeeee15;
      border: 1px solid  #c1ccd6;
   }
}

.timelineOverlayText {
   color: #7e96ac;
   font-weight: 500;
   position: absolute;
   border-radius: 10px;
   padding: 2px 5px;
   transition: $transition;
   top: 5px;
   right: 5px;
   font-size: 12px;
}

.timelineOverlayTextError {
   @extend .timelineOverlayText;
   color: #9d9d9d;
}

.timelineOverlayBtn {
   position: absolute;
   top: 0;
   right: 0;
   margin-left: 20px;
   background: rgb(247, 247, 247);
   background: #fff;
   color: rgb(207, 58, 58);
   border: none;
   border-radius: 7px;
   padding: 5px 7px;
   display: flex;
   align-items: center;
   outline: none;
   cursor: pointer;
   transition: $transition;

   span {
      margin-right: 5px;
   }

   &:hover {
      background: rgb(247, 247, 247);
   }
}

.timelineOverlayIcon {
   font-size: 17px !important;
}