@import '../../styles/variables.scss';

.chat {
   &__wrapper {
      display: flex;
      flex-direction: column;
      background: #fff;
      width: 350px;
      height: 450px;
      border-radius: 24px;
      box-shadow: 4px 4px 24px rgba(85, 106, 125, 0.3);
      overflow: hidden;
   }

   &__header {
      background: $pink;
      display: flex;
      justify-content: space-between;
      height: 45px;
      align-items: center;
      cursor: move;
   }

   &__walkerName {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      padding: 0 20px;
   }

   &__closeIcon {
      background: #ff7e72;
      width: 40px;
      height: 40px;
      position: relative;
      cursor: pointer;
      
      img {
         width: 15px;
         height: 15px;
         position: absolute;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
      }
   }
}

.chat {
   padding: 0 0px 20px 0px;
   box-sizing: border-box;
   display: flex;
   flex-direction: column;
   flex: 1;
   height: 400px;
   overflow-y: auto;

   &__messageUserWrapper {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 14px;
      margin-right: 15px;
      
      .chat__message {
         margin-right: 10px;
      }
   }
   
   &__messageWalkerWrapper {
      display: flex;
      align-items: flex-end;
      margin-top: 14px;
      width: fit-content;
      
      .chat__message {
         margin-left: 10px;
      }
   }
   
   &__messageAvatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 3px 2px 3px rgba($color: $darkGrey, $alpha: .3);
      box-sizing: border-box;

      
      img {
         height: 100%;
         width: 100%;
         object-fit: cover;
      }
   }
   
   &__message {
      color: $darkBlue;
      background: #edeeee;
      padding: 10px 35px 10px 10px;
      border-radius: 8px;
      position: relative;
      text-align: start;
      font-size: 13px;
      width: fit-content;
      // max-width: 90%;
      max-width: 200px;
      line-height: 16px;
      // height: fit-content;
      word-wrap: break-word;
   }

   &__messageTime {
      color: $darkGrey;
      font-size: 11px;
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-weight: 500;
   }

   &__inputWrapper {
      display: flex;
      justify-content: space-between;
      box-shadow: 3px 3px 8px rgba(85, 106, 125, 0.2);
      border-radius: 33px;
      margin-top: 10px;
      align-items: center;
      min-height: 43px;
      max-height: 100px;
      position: relative;
   }

   &__typing {
      font-size: 12px;
      color: rgb(153, 153, 153);
      position: absolute;
      top: -20px;
      left: 15px;
   }
   
   &__input {
      outline: none;
      border: none;
      border-radius: 20px;
      padding: 0 15px;
      max-height: 100px;
      overflow: auto;
      line-height: 17px;
      box-sizing: border-box;
      width: 100%;
      font-size: 13px;
      color: $darkGrey;
      font-family: 'Poppins', sans-serif;
      resize: none;
      margin: 5px 0;

      &::placeholder {
         font-size: 13px;
         color: $darkGrey;
         font-family: 'Poppins', sans-serif;
      }
   }

   &__inputButtons {
      display: flex;
      height: 43px;
      align-self: flex-end;
      align-items: center;
   }

   &__attachPhoto {
      cursor: pointer;
      margin: 0 10px;
      cursor: pointer;
   }

   &__sendBtn {
      background: $pink;
      height: 100%;
      box-sizing: border-box;
      border-radius: 33px;
      cursor: pointer;
      width: 75px;
      position: relative;
      transition: $transition;

      &:hover {
         background: darken($color: $pink, $amount: 7)
      }
   }
   
   &__sendBtnText {
      position: absolute;
      color: #fff;
      top: 50%;
      font-size: 14px;
      font-weight: 500;
      left: 50%;
      transform: translate(-50%, -50%);
   }
}