@import '../../styles/variables.scss';
@import '../../styles/commonStyles.scss';

.profile {
   border: 1px solid transparent;
   display: flex;
   flex-wrap: wrap;
   align-items: flex-start;
   justify-content: space-between;
   
   
   &__wrapper {
      width: 1250px;
      max-width: 90%;
      margin: 0 auto;
      margin-top: 75px;
      position: relative;
   }

   &__imgWrapper {
      border-radius: 28px;
      position: absolute;
      top: 0;
      left: 0;
      width: 255px;
      height: 192px;
      overflow: hidden;
      box-shadow: 4px 4px 12px rgba(85, 106, 125, 0.25);
      z-index: 10;

      img {
         object-fit: cover;
         width: 100%;
         height: 100%;
      }
   }

   &__imgTabletWrapper {
      display: none;
   }

   &__infoWrapper {
      width: fit-content;
      width: 385px;
   }

   &__info {
      width: 350px;
      margin: 40px 0 0 35px;
      box-sizing: border-box;
      padding: 25px;
      margin-bottom: 30px;
      border-radius: 24px;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(88, 111, 138, 0.17);
      position: relative;
   }

   &__mainInfo {
      display: flex;
      flex-direction: column;
      margin-left: auto;
      width: 80px;
      align-items: center;
      position: absolute;
      right: 30px;
      top: 20px;
      text-align: center;
   }


   &__mainInfoWrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }

   &__infoText {
      font-size: 14px;
      text-transform: capitalize;
      color: $darkGrey;
   }
   
   &__infoValue {
      display: flex;
      font-size: 14px;
      margin-top: 6px;
      color: $darkBlue;

      img {
         margin-right: 5px;
      }
   }

   &__infoLine {
      width: 100%;
      height: 1px;
      background: #d3d4d8a5;
      margin: 7px 0;
   }

   &__aboutWrapper {
      position: relative;
      margin-top: 150px;
   }

   &__location {
      color: $darkGrey;
      font-size: 15px;
      margin-top: 5px;
   }

   &__locationInfo {
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      margin-top: 5px;

      img {
         margin-right: 10px;
         width: 18px;
      }
   }

   &__name {
      color: $darkBlue;
      font-size: 18px;
      font-weight: 500;
      margin-top: 10px;
      text-align: start;
   }

   &__aboutLabel {
      font-size: 16px;
      font-weight: 500;
      margin-top: 15px;
      color: $darkBlue;
      text-align: start;
   }

   &__about {
      font-size: 14px;
      margin-top: 12px;
      color: $darkBlue;
      line-height: 20px;
      font-weight: 300;
      line-height: 23px;
      text-align: start;
   }

   // Dog's supports

   &__supportsDogsTitle {
      color: $darkBlue;
      font-size: 14px;
      font-weight: 500;
      text-align: start;
      margin-left: 40px;
   }

   &__supportsDogs {
      display: flex;
      color: $darkBlue;
      margin-left: 40px;
      margin-top: 10px;
   }

   &__supportsDog {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      margin-right: 14px;
      width: 60px;
      height: 60px;
      background: #FFFFFF;
      box-shadow: 1px 1px 6px rgba(32, 33, 65, 0.1);
      border-radius: 8px;
      padding: 5px;
   }

   &__supportsDogLabel {
      font-size: 12px;
   }

   // Slider

   &__galleryTitle {
      font-size: 16px;
      color: $darkBlue;
      font-weight: 500;
      margin: 30px 0 12px 10px;
      text-align: start;
   }

   &__gallery {
      width: 100%;
      margin-left: auto;
      position: relative;

      .photoWrapper {
         width: 130px;
         height: 250px;
         padding: 7px;
         box-sizing: border-box;
         outline: none;
         
         img {
            border-radius: 18px;
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      .arrowPrev {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         left: -25px;
         transition: $transition;
         cursor: pointer;
      }

      .arrowNext {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         right: -25px;
         transition: $transition;
         cursor: pointer;
      }
   }

   &__datePickerWrapper {
      background: #e9f1f8;
      border-radius: 12px;
      width: fit-content;
      padding: 10px 20px !important;
      margin: 0 auto;

      > div {
         padding-top: 0;
      }
   }

   &__datePicker {
      background: #FFFFFF;
      border-radius: 3px;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      width: 170px;
      font-weight: 500;
      color: $darkBlue;

      input {
         font-size: 13px;
         padding: 10px;
      }
   }

   &__datePickerIcon {
      width: 18px;
   }

   &__timelineWrapper {
      padding: 20px 100px;
   }

   &__timelineInnerWrapper {
      margin-top: 20px;
      position: relative;
   }

   &__timeLineLabel {
      color: #90929d;
      font-size: 13px;
      text-align: center;
   }

   &__timelineDate {
      text-align: start;
   }

   // confirmation


   &__line {
      height: 1px;
      width: 100%;
      background: #ebecee;
   }

   &__confirmation {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 20px 75px;
   }

   &__mobileTimeSelection {
      display: none;
   }

   &__confirmationGroup {
      display: flex;
      flex-direction: column;
      padding: 0 25px;
      border-left: 1px solid #ebecee;
      align-items: flex-start;
      min-width: 50px;

      &:first-child {
         border-left: none;
      }
   }

   &__confirmationGroupTime {
      width: 150px;
   }
   &__confirmationLabel {
      color: $lightGrey;
      font-size: 13px;
      text-align: start;
   }

   &__confirmationValue {
      color: $darkBlue;
      font-size: 15px;
      margin-top: 0px;
   }
   
   &__btnConfirm {
      background: $pink;
      font-size: 14px;
      color: #ffffff;
      font-weight: 500;
      padding: 8px 30px;
      border-radius: 12px;
      transition: $transition;
      width: max-content;
      margin-top: 30px;
      margin-left: 0;
      z-index: 10;
      position: relative;

      &:hover {
         background: darken($color: $pink, $amount: 5);
      }
   }

   &__btnConfirmDisable {
      background: rgb(218, 218, 218);
      font-size: 14px;
      color: #ffffff;
      font-weight: 500;
      padding: 8px 30px;
      border-radius: 12px;
      transition: $transition;
      pointer-events: none;
      width: max-content;
      margin-top: 30px;
      margin-left: 0;
      z-index: 10;
      position: relative;
   }

   &__availability {
      flex: 1;
      margin-bottom: 200px;
      position: relative;
   }

   &__availability--disabled {
      flex: 1;
      margin-bottom: 200px;
      opacity: .4;
      pointer-events: none;
      filter: blur(2px);
   }

   &__availabilityInfoText {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: lighten($lightBlue, 5);
      padding: 20px 20px;
      color: #485a6b;
      border-radius: 5px;
      box-shadow: $shadow;
      z-index: 100;
      display: flex;
      flex-direction: column;
      grid-gap: 15px
   }

   &__mapWrapper {
      margin-top: 10px;
   }

   &__shelterImageWrapper {
      width: 100px;
      height: 70px;
      border-radius: 10px;
      overflow: hidden;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &__shelter {
      width: 350px;
      margin: 35px 0 0 35px;
      box-sizing: border-box;
      padding: 1px 25px 25px 25px;
      margin-bottom: 30px;
      border-radius: 24px;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(88, 111, 138, 0.17);
      position: relative;
   }
   
   &__dashedLine {
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
   }

   &__shelterInfo {
      display: flex;
      align-items: flex-start;
      grid-gap: 15px;
      margin-top: 10px;
   }

   &__shelterName {
      font-size: 16px;
      color: $darkBlue;
   }
}


// slider

.slick-slide {
   outline: none;
}

.slick-arrow {
   height: 50px;
}

.slick-next:before, .slick-prev:before {
   color: transparent;
}
@media (max-width: 1200px) {
   .profile {
      &__timelineWrapper {
         padding: 20px 0 20px 70px;
      }

      &__confirmation {
         padding: 20px 75px 20px 45px;
      }
   }
}

@media (max-width: 1100px) {
   .profile  {
      box-sizing: border-box;

      &__wrapper {
         margin-top: 20px;
      }

      &__imgWrapper{
         position: relative;
      }

      &__imgWrapper {
         display: none;
      }

      &__infoWrapper {
            display: flex;
      }

      &__dashedLine {
         top: 50%;
         left: -20px;
         transform: translateY(-50%);
         transform: rotate(90deg);
      }

      &__shelter {
         flex: 1;
         margin: 0;
         min-width: 300px;
      }

      &__imgInfoWrapper {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
      }

      &__imgTabletWrapper {
         width: 100%;
         display: block;
         border-radius: 12px;
         top: 0;
         left: 0;
         height: 220px;
         overflow: hidden;
         box-shadow: 2px 3px 12px rgba(85, 106, 125, 0.185);
         z-index: 10;

         img {
            object-fit: cover;
            width: 100%;
            height: 100%;
         }
      }

      &__infoWrapper {
         width: 100%;
         grid-gap: 37px;
      }

      &__info {
         min-width: 200px;
         margin: 0;
      }

      &__mainInfo {
         position: relative;
         margin-left: 0;
         margin-top: 20px;
         width: 100%;
         right: 0;
         top: 0;
         flex-direction: row;
         justify-content: space-evenly;
      }

      &__mainInfoWrapper{
      }

      &__infoLine {
         display: none;
      }

      &__aboutWrapper {
         margin-top: 0px;
         padding: 0 30px;
         flex: 1;
      }

      &__locationInfo {
      }

      &__datePickerWrapper {
         margin-top: 50px;
      }

      &__timelineWrapper {
         min-width: 400px;
         padding: 20px 20px 20px 25px;
      }

      &__confirmation {
         padding: 20px 75px 20px 0px;
      }
   }
}

@media (max-width: 768px) {
   
   .profile {

      &__imgInfoWrapper {
         margin: 0 auto;
         margin-bottom: 20px;
      }

      &__aboutWrapper {
         padding: 0;
         width: 100%;
      }

      &__info {
         // padding: 0;
      }

      &__supportsDogsTitle {
         margin-left: 25px;
      }

      &__supportsDogs {
         margin-left: 25px;
      }
   }
}

@media (max-width: 600px) {
   .profile {
      &__info {
         width: 100%;
      }
      &__shelter {
         width: 100%;
         min-width: 0;
      }

      &__infoWrapper {
         flex-wrap: wrap;
      }

      &__dashedLine {
         position: absolute;
         top: -30px;
         left: 50%;
         transform: translateX(-50%);
      }

      &__desktopTimelines {
         display: none;
      }
     
      &__mobileTimeSelection {
         display: block;
      }

   }
}


@media (max-width: 420px) {
   .profile {
      &__imgInfoWrapper {
         width: 100%;
      }

      &__imgTabletWrapper {
         width: 100%;
         height: 150px;
      }
   }
}