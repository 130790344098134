@import './variables.scss';

// map


.popup__closeBtn {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}

.title {
  font-size: 20px;
  text-align: left;
  font-weight: 500;
}

a {
  color: $pink;
  transition: $transition;

  &:hover {
    // color: lighten($color: $pink, $amount: 5);
  }
}

.fadeIn {
  animation: fadeIn .5s ease-in-out;
}

.text {
  color: $darkBlue;
  margin-top: 5px;
  font-size: 14px;
}

.secondaryText {
  color: $lightGrey;
  font-size: 14px;
}

.label {
  color: $lightGrey;
  margin-top: 15px;
  font-size: 14px;
}

.wrap {
  flex-wrap: wrap;
}

.autocomplete-suggestions {
  background: #fff;
  z-index: 100;
  border-radius: 8px;
  position: absolute;
  max-width: 520px;
  overflow: hidden !important;
}

.autocomplete-suggestions--bordered {
  background: #fff;
  z-index: 100;
  border-radius: 8px;
  position: absolute;
  border: 1px solid lighten($color: $pink, $amount: 10);
  max-width: 400px;
  overflow: hidden !important;
}

.suggestion-item {
  padding: 4px 20px;
  font-size: 14px;
  background: '#fff' !important;
  border-bottom: 1px solid #eee;

  &:hover {
    background: '#ddd' !important;
  }
}

.suggestion-item--active {
  font-size: 14px;
  padding: 4px 20px !important;
  background: '#ddd' !important;
  cursor: pointer !important;
  border-bottom: 1px solid #eee;
}

.loader {
  position: absolute;
  width: fit-content;
  bottom: -55px;
  left: 50%;
  transform: translateX(-50%);
}


// cropper 

.reactEasyCrop_Container {
  .reactEasyCrop_CropArea {
    color: rgba(255, 255, 255, 0.468);
  }
}

.MuiPaper-root .MuiButtonBase-root {
  padding: 8px !important;
  display: flex !important;
}

.MuiPaper-root .MuiButtonBase-root.Mui-selected {
  color: #fff !important;
}

// mui button

.MuiButton-root.MuiButton-text {
  color: inherit;
  
  .MuiTouchRipple-root {
    color: $pink !important;
    opacity: .7 !important;
 }
}

@keyframes fadeIn {
  0% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}
