$pink: #ff9085;
$lightGrey: #90929d;
$darkGrey: #646775;
$greyDescText: #4d4d4d;
$darkBlue: #21263a;
$transition:  .1s ease-in-out;
$lightBlue: #D4E4F2;
$paddingBtn: 18px 40px;
$shadow: 2px 2px 4px rgba(32, 33, 65, 0.08);


@mixin plainText {
   font-size: 17px;
   color: #646775;
}

@mixin labelText {
   font-size: 19px;
   color: #646775;
}

.flex {
   display: flex;
}

.flexColumn {
   display: flex;
   flex-direction: column;
}

.flexBetween {
   display: flex;
   justify-content: space-between;
   grid-gap: 20px;
}

.itemsCenter {
   align-items: center;
}

.popup {
   width: 920px;
   max-width: 90%;
   margin: 0 auto;
   background: #ffffff;
   position: absolute;
   top: 30%;
   left: 50%;
   transform: translate(-50%, -30%);
   box-shadow: 4px 4px 24px rgba(85, 106, 125, 0.3);
   height: fit-content;
   border-radius: 28px;
}

.popupTitle {
   font-size: 20px;
   font-weight: 500;
   text-align: center;
   color: $darkBlue;
}

.popupSubtitle {
   font-size: 18px;
   color: $darkGrey;
   text-align: center;
   margin-bottom: 20px;
}

.closeBtn {
   position: absolute;
   top: 24px;
   right: 24px;
   cursor: pointer;
}

.emptyBtn {
   background: #ffffff;
   color: $pink;
   padding: $paddingBtn;
   border-radius: 12px;
   transition: $transition;
   cursor: pointer;
   border: 2px solid $pink;

   &:hover {
      background: rgba($color: $pink, $alpha: .2);
   }
}

.fullBtn {
   background: $pink;
   color: #ffffff;
   padding: $paddingBtn;
   transition: $transition;
   border-radius: 12px;
   border: 2px solid transparent;
   cursor: pointer;

   &:hover {
      background: darken($color: $pink, $amount: 7);
   }
}

.mr {
   margin-right: 15px;
}

.ml {
   margin-left: 15px;
}

