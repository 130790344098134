@import '../../../styles/variables.scss';
@import '../../../styles/commonStyles.scss';

$pink: #ff9085;
$lightGrey: #90929d;
$darkGrey: #646775;
$greyDescText: #4d4d4d;
$darkBlue: #21263a;
$transition:  .3s ease-in-out;
$paddingBtn: 18px 40px;

@mixin plainText {
   font-size: 17px;
   color: #646775;
}

@mixin labelText {
   font-size: 19px;
   color: #646775;
}

.flex {
   display: flex;
}

.flexColumn {
   display: flex;
   flex-direction: column;
}

.flexBetween {
   display: flex;
   justify-content: space-between;
}

.closeBtn {
   position: absolute;
   top: 24px;
   right: 24px;
   cursor: pointer;
}

// .....

.addCard {
   position: relative;
   padding: 60px 160px 50px 160px;

   &__title {
      @extend .popupTitle;
      text-align: center;
   }

   &__closeBtn {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
   }

   &__card {
      color: #fff;
      width: 360px;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 5px 12px 10px 12px;
      border-radius: 12px;
      background-image: linear-gradient(to right bottom, #ff9085, #fe9b8e, #fea597, #fcafa1, #fbb9ab);
      position: relative;
   }

   &__loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.582);

      .lds-roller {
         display: inline-block;
         position: absolute;
         width: 80px;
         height: 80px;
         top: 50%;
         left: 50%;
         transform: translate(-50%, -50%);
       }
       
       .lds-roller div {
         animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
         transform-origin: 40px 40px;
       }
       .lds-roller div:after {
         content: " ";
         display: block;
         position: absolute;
         width: 7px;
         height: 7px;
         border-radius: 50%;
         background: #fff;
         margin: -4px 0 0 -4px;
       }
       .lds-roller div:nth-child(1) {
         animation-delay: -0.036s;
       }
       .lds-roller div:nth-child(1):after {
         top: 63px;
         left: 63px;
       }
       .lds-roller div:nth-child(2) {
         animation-delay: -0.072s;
       }
       .lds-roller div:nth-child(2):after {
         top: 68px;
         left: 56px;
       }
       .lds-roller div:nth-child(3) {
         animation-delay: -0.108s;
       }
       .lds-roller div:nth-child(3):after {
         top: 71px;
         left: 48px;
       }
       .lds-roller div:nth-child(4) {
         animation-delay: -0.144s;
       }
       .lds-roller div:nth-child(4):after {
         top: 72px;
         left: 40px;
       }
       .lds-roller div:nth-child(5) {
         animation-delay: -0.18s;
       }
       .lds-roller div:nth-child(5):after {
         top: 71px;
         left: 32px;
       }
       .lds-roller div:nth-child(6) {
         animation-delay: -0.216s;
       }
       .lds-roller div:nth-child(6):after {
         top: 68px;
         left: 24px;
       }
       .lds-roller div:nth-child(7) {
         animation-delay: -0.252s;
       }
       .lds-roller div:nth-child(7):after {
         top: 63px;
         left: 17px;
       }
       .lds-roller div:nth-child(8) {
         animation-delay: -0.288s;
       }
       .lds-roller div:nth-child(8):after {
         top: 56px;
         left: 12px;
       }
       @keyframes lds-roller {
         0% {
           transform: rotate(0deg);
         }
         100% {
           transform: rotate(360deg);
         }
       }
       
   }

   &__cardLabel {
      font-size: 14px;
      margin: 15px 0 4px 0;
   }

   &__cardInput {
      padding: 7px 12px;
      color: #fff;
      font-weight: 560;
      outline: none;
      background: #fdc2b9d3;
      font-size: 16px;
      border: none;
      width: 100%;
      border-radius: 4px;
      box-sizing: border-box;
   }
   
   &__innerWrapper {
      position: relative;
      width: 100%;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
   }
   
   &__expiryDateWrapper {
      width: 40%;
   }

   &__cvvWrapper {
      width: 40%;
   }

   &__inputsWrapper {
      display: flex;

      span {
         line-height: 30px;
         margin: 0 2px;
      }
   }

   &__saveBtn {
      margin: 0 auto;
      margin-top: 32px;
      background: $pink;
      color: #ffffff;
      padding: 8px 28px;
      width: fit-content;
      font-size: 14px;
      font-weight: 500;
      border-radius: 7px;
      cursor: pointer;
      transition: $transition;

      &:hover {
         background: darken($color: $pink, $amount: 7);
      }
   }

}

   
@media (max-width: 992px) {
   .addCard {
      padding: 60px;
   }
}