.confirmation {
    &__buttons {
        display: flex;
        align-items: center;
        grid-gap: 20px;
        margin-top: 20px;
    }

    &__title {
        font-weight: 500;
        font-size: 18px;
        width: 300px;
        text-align: center;
    }
}