@import '../../styles/variables.scss';

.userProfile {
   width: 1250px;
   max-width: 90%;
   margin: 0 auto;
   margin-top: 40px;
   margin-bottom: 50px;
   background: #FFFFFF;
   box-shadow: 4px 4px 14px rgba(85, 106, 125, 0.2);
   border-radius: 28px;
   display: flex;
   box-sizing: border-box;
   min-height: 520px;
   text-align: start;
   position: relative;

   &__menu {
      padding-top: 48px;
      padding-bottom: 48px;
      box-shadow: 2px 2px 12px rgba(85, 106, 125, 0.12);
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      border-radius: 28px 0 0 28px;
      width: 265px;
      flex: none;
   }

   &__menuMobile {
      display: none;
   }

   &__title {
      color: $darkBlue;
      font-size: 18px;
      font-weight: 500;
   }

   &__menuTitle {
      color: $darkBlue;
      font-size: 18px;
      font-weight: 500;
      margin-left: 75px;
   }

   &__menuItem {
      margin-top: 40px;
      color: #a6a8b0;
      text-transform: capitalize;
      transition: $transition;
      cursor: pointer;
      width: 100%;
      font-size: 16px;
      padding: 0 75px;
      box-sizing: border-box;

      &:hover {
         color: $darkBlue;
      }
   }

   &__menuItem.active { 
      color: $darkBlue;
   }

   &__logOutBtnWrapper {
      color: $pink;
      display: block;
      margin: auto 75px 35px 75px;
      cursor: pointer;

      &:hover .userProfile__logOutBtn {
         color: darken($color: $pink, $amount: 10);
      }
   }

   &__logOutBtnWrapperMobile {
      display: none;
      border: 1px solid $darkGrey;
      border-radius: 7px;
      align-items: center;
      justify-content: center;
      padding: 2px 10px;
      cursor: pointer;
      transition: $transition;
      font-size: 14px;
      color: $darkGrey;
      width: 90%;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 7px 15px;

      span {
         margin-left: 5px;
      }

      &:hover {
         border: 1px solid lighten($color: $darkGrey, $amount: 20);
      }
   }

   &__logOutBtn {
      text-transform: capitalize;
      border-bottom: 2px solid $pink;
      width: fit-content;
      padding: 0 0 2px 0;
      font-size: 16px;
      font-weight: 500;
      transition: $transition;
      white-space: nowrap;
   }

   &__tabIndicator {
      position:absolute;
      width:4px;
      height:24px;
      background: $pink;
      left: 261px;
      top: 119px;
      border-radius: 2px;
      transition: $transition;
   }

   &__tabIndicator.active {
      @extend .userProfile__tabIndicator;
         top: 185px;
   }

   // photo section

   &__avatarSection {
      align-items: center;
      display: flex;
      margin-bottom: 20px;
   }

   &__avatarWrapper {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;

      img {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &__avatarButtons {
      margin-left: 20px;
      display: flex;
      grid-gap: 10px;
      color: $pink;
   }

   &__avatarUploadBtn {
      width: fit-content;
   }

   &__avatarDeleteBtn {
      border: 1px solid $pink;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 10px;
      cursor: pointer;
      transition: $transition;

      &:hover {
         background: lighten($color: $pink, $amount: 20);
      }
   }

   
   &__info {
      margin: 48px 50px;
      width: 750px;
      max-width: 90%;
      color: $darkBlue;
      top: 0;
      transition:all 500ms ease-in-out;
      box-sizing: border-box;
   }

   &__userName {
      font-weight: 500;
      font-size: 16px;
      text-transform: capitalize;
   }

   &__userPosition {
      color: $lightGrey;
      font-weight: 400;
      font-size: 14px;
      margin-top: 7px;
   }

   &__form {
      margin: 0;
      padding: 0;
      margin-top: 40px;
      display: grid;
      grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
      grid-gap: 20px;
      align-items: flex-start;
   }

   &__formLabel {
      text-transform: capitalize;
      font-size: 12px;
      font-weight: 400;
      color: #808080;
      
      + svg {
         position: absolute;
         bottom: 10px;
         left: 10px;
         font-size: 30px;
         color: $pink;
         pointer-events: none;
      }
   }

   &__inputWrapper {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column-reverse;
   }

   &__inputWrapper:has(textarea) {
      height: 100%;
      display: flex;
      justify-content: flex-start;

      textarea {
         margin-bottom: auto;
         resize: none;
         height: 100%;
      }
   }


   &__socialsWrapper {
      display: grid;
      grid-gap: 20px;
   }

   &__formInput {
      font-size: 15px;
      color: $darkBlue;
      padding: 15px 16px;
      background: #ffffff;
      box-shadow: $shadow;
      border: 1px solid #eee;
      border-radius: 8px;
      margin-top: 8px;
      outline: none;
      transition: $transition;

      &:focus  {
         outline: 2px solid lighten($color: $pink, $amount: 20);;
         border: 1px solid lighten($color: $pink, $amount: 1);
      }

      &:focus + label {
         color: $pink;
         transition: $transition;
      }
   }

   &__formSocialInput {
      @extend .userProfile__formInput;
      padding: 15px 16px 15px 45px;
   }

   &__saveBtnWrapper {
      width: fit-content;
      margin: 0 auto;
      margin-top: 60px;
   }

   // &__saveBtn {
   //    background: $pink;
   //    color: #ffffff;
   //    font-size: 14px;
   //    padding: 10px 25px;
   //    border: none;
   //    font-weight: 500;
   //    border-radius: 7px;
   //    margin-top: 60px;
   //    transition: $transition;
   //    cursor: pointer;

   //    &:hover {
   //       background: darken($color: $pink, $amount: 7);
   //    }
   // }

   // card section

   &__cardTitle {
      font-size: 16px;
      color: $darkBlue;
      margin-left: 10px;
   }

   &__cards {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
   }

   &__card {
      padding: 20px 16px;
      width: 253px;
      margin-right: 24px;
      margin: 10px;
      height: 150px;
      color: #ffffff;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-image: linear-gradient(to right bottom, #ff9085, #fe9b8e, #fea597, #fcafa1, #fbb9ab);
   }

   &__cardNumLogo {
      display: flex;
      justify-content: space-between;  
      align-items: center; 
   }

   &__cardNum {
      font-size: 13px;
      font-weight: 500;
   }

   &__cardDateLabel {
      font-size: 10px;
      font-weight: 500;
      color: #ffe3df;
   }

   &__cardDate {
      font-size: 13px;
      font-weight: 500;
      margin-top: 5px;
   }

   // ADD CARD

   &__infoCard {
      margin: 48px 30px;
      width: fit-content;
      flex: 1;
      color: $darkBlue;
      height: fit-content;
      top: 0;
      transition:all 500ms ease-in-out;
   }

   
   &__addCard {
      margin: 12px;
      position: relative;
      padding: 20px 16px;
      width: 253px;
      height: 150px;
      border-radius: 12px;
      transition: $transition;
      background-image: linear-gradient(to right bottom, #f7f9fb, #f8f8fb, #f9f8fb, #faf7fa, #fcf6f8, #fdf3f5, #feeff1, #ffecec, #ffe5e5, #ffdede, #ffd7d7, #ffd0d0);
      background-size: 300% 100%;
      cursor: pointer;

      &:hover {
         background-position: 100% 0;
         transition: $transition;
      }
   }

   &__addCardText {
      position: absolute;
      color: $darkBlue;
      font-size: 19px;
      display: flex;
      align-items: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
         margin-right: 10px;
      }
   }


   // WALKS

   &__walksSection {
      margin: 48px 50px;
      width: 100%;
   }

   &__walksFilter {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
   }

   &__walksFilterItem {
      font-weight: 500;
      border: 1px solid lighten($color: $pink, $amount: 15);
      border-radius: 5px;
      padding: 4px 15px;
      color: $pink;
      font-size: 14px;
      margin-right: 15px;
      margin-bottom: 15px;
      cursor: pointer;
      white-space: nowrap;
      text-transform: capitalize;

      &:hover {
         background: rgba($color: $pink, $alpha: .1)
      }
   }

   &__walksFilterItem--active {
      @extend .userProfile__walksFilterItem;
      background: $pink;
      color: white;
      pointer-events: none;
   }


   &__walks {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
   }

   &__walk {
      background: #FFFFFF;
      box-shadow: 0px 4px 12px rgba(88, 111, 138, 0.17);
      font-size: 14px;
      width: 300px;
      border-radius: 18px;
      position: relative;
      padding: 1px;
   }

   &__walkLabel {
      color: $lightGrey;
      margin-top: 15px;
   }

   &__walkText {
      color: $darkBlue;
      margin-top: 5px;
   }

   &__walkMainInfo {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      // padding: 20px 0 0px 20px;
   }

   &__walkSizeName {
      display: flex;
      align-items: center;
      margin: 20px 0 0 20px;

   }

   &__walkInfo {
      margin: 0 20px 20px 20px;
   }

   &__dogName {
      font-size: 16px;
      font-weight: 500;
      margin-left: 15px;
   }

   &__walkSettingsBtn {
      cursor: pointer;
      align-self: stretch;
      padding: 5px 20px 5px 10px;
      width: fit-content;
      margin-left: auto;

      img {
         margin-top: 20px;
      }

      &:hover {
         opacity: .5;
      }
   }

   &__walkSettings {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #ffffffdc;
      backdrop-filter: blur(2px);
      z-index: 9;
      border-radius: 18px;
   }

   &__walkSettingsContent {
      padding: 20px;
   }

   &__walkSettingsItem {
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      padding: 10px 0;
      cursor: pointer;
      font-weight: 500;
      transition: $transition;

      &:hover {
         color: lighten($color: $darkBlue, $amount: 40);
      }
   }

}

@media (max-width: 1200px) {
   .userProfile {
      &__info {
         margin: 48px 40px;
      }
   }
}

@media (max-width: 1100px) {
   .userProfile {

      &__from {
         margin-top: 10px;
      }

      &__info {
         flex: 1;
      }

      &__logOutLink {
         margin: auto 50px 0 50px;
      }

      &__formInnerWrapper {
         margin-top: 0;
         flex-direction: column;

         div {
            margin-top: 20px;
         }
      }
   }
}

@media (max-width: 768px) {
   .userProfile {
      &__menu {
         width: 200px;
      }

         &__menuTitle {
            margin-left: 30px;
      }

      &__menuItem {
         padding:  0 30px;
      }

      &__tabIndicator {
         left: 196px;
         top: 119px;
         border-radius: 2px;
         transition: $transition;
      }

      &__tabIndicator.active {
         top: 185px;
      }
   }
}

@media (max-width: 640px) {
   .userProfile {
      margin-top: 30px;
      border-radius: 15px;
      box-shadow: $shadow;
      border: 1px solid #eee;

      &__menu {
         display: none;
         // border-radius: 15px 0 0 15px;
      }

      &__tabIndicator {
         display: none;
      }

      &__info {
         margin: 20px;
         width: 100%;
         max-width: 100%;
      }

      &__walksSection {
         margin: 20px 20px 40px 20px;
      }
      
      &__infoCard {
         margin: 15px;
         width: 100%;
         max-width: 100%;
      }
      
      &__form {
         margin-top: 20px;
      }

      &__menuMobile {
         display: flex;
         grid-gap: 20px;
         justify-content: center;
         text-align: center;
      }

      &__menuMobileItem {
         font-size: 14px;
         padding: 8px 20px;
         border: 1px solid lighten($color: $pink, $amount: 15);
         color: $pink;
         border-radius: 7px;
         width: 100px;
      }

      &__menuMobileItem.active {
         background: $pink;
         color: #fff;
         border: 1px solid $pink;
      }

      &__cards {
         justify-content: center;
      }

      &__logOutBtnWrapperMobile {
        display: flex;
      }
   }
}


@media (max-width: 420px) {
   .userProfile {
      &__walksFilterItem {
         padding: 3px 12px;
         font-weight: 400;
         margin-right: 10px;
         margin-bottom: 10px;
      }

      &__walk {
         width: 100%;
         box-shadow: 0px 4px 12px rgba(88, 111, 138, 0.1);
      }
   }
}
