@import '../../styles/variables.scss';

button.active {
   background: $pink;
   color: #ffffff;
   font-weight: 500;

   &:hover {
      background: $pink;
      color: #ffffff;
   }
}

// banner

.banner {
   width: 1250px;
   max-width: 90%;
   margin: 0 auto;
   margin-top: 100px;
   position: relative;

   &__wrapper {
      width: 100%;
   }

   &__tabletImgBg {
      display: none;
   }

   &__tabletImg {
      display: none;
   }

   &__img {
      max-width: 600px;
      margin:  0 auto;

      img {
         margin: 0 auto;
         display: block;
         width: 100%;
      }
   }
   
   &__title {
      color: $darkBlue;
      font-size: 38px;
      font-weight: 400;
      width: 80%;
      margin: 0 auto;
      text-align: center;
      margin-top: 40px;
   }


   &__imgBackground {
      position: absolute;
      top: 90px;
      right: 20px;
      width: 445px;
      z-index: -1;
   }

}
.selection {
   display: flex;
   justify-content: space-between;
   grid-gap: 20px;
   width: 80%;
   margin:  0 auto;
   margin-top: 50px;
   flex-wrap: wrap;


   &__item {
      flex: 1;
      border-radius: 20px !important;
      box-shadow: 0px 2px 16px rgba(88, 111, 138, 0.16);
      background: #fff;
      padding: 30px !important;
      text-transform: none !important;
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
      cursor: pointer;
      border: 2px solid transparent !important;
      transition: $transition;
      min-width: 300px !important;
      max-width: 700px !important;
      margin: 0 auto !important;
      color: $darkBlue !important;


      &:hover {
         border: 2px solid $pink !important;
         background: none !important;
      }
   }

   &__info {
      text-align: left;
   }

   &__title {
      font-size: 22px;
      line-height: 28px;
      font-weight: 500;
   }

   &__subtitle {
      font-size: 16px;
      color: $lightGrey;
      margin-top: 10px;
      font-weight: 400;
   }

   &__img {
      width: 50%;
   }
}

// about us

.about {
   width: 1250px;
   max-width: 90%;
   margin: 0 auto;

   &__wrapper {
      overflow: hidden;
      position: relative;
      padding: 0 0 200px 0;
   }

   &__ellipseMiddleLeft {
      position: absolute;
      width: 156px;
      height: 156px;
      top: 100px;
      left: -80px;
      background: #ffdeda;
      border-radius: 50%;
      filter: blur(10px);
      -webkit-filter: blur(10px);
      z-index: -5;
   }

   &__ellipseMiddleRight {
      position: absolute;
      width: 270px;
      height: 270px;
      bottom: 0;
      right: -175px;
      background: #f2f7fb;
      border-radius: 50%;
      filter: blur(10px);
      -webkit-filter: blur(10px);
      z-index: -5;

   }

   &__line {
      width: 62px;
      background: $pink;
      height: 4px;
      margin: 0 auto;
      margin-top: 75px;
      border-radius: 4px;
   }

   &__title {
      font-size: 32px;
      font-weight: 500;
      color: $darkBlue;
      margin: 100px 0 0 0;
      text-align: center;
   }
   
   &__container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
      grid-gap: 30px;
   }

   &__item {
      display: flex;
      margin-top: 70px;
      flex-direction: column;
      align-items: center;
   }

   &__img {
      height: 230px;
   }

   &__itemTitle {
      margin-top: 40px;
      font-size: 24px;
      font-weight: 500;
      position: relative;

      &::after {
         content: "";
         position: absolute;
         bottom: -5px;
         left: 0;
         width: 100%;
         height: 19px;
         background: #FFDEDA;
         z-index: -1;
      }
   }

   &__itemDesc {
      margin-top: 24px;
      text-align: center;
      font-size: 18px;
      line-height: 28px;
      color: $greyDescText;
   }
}

// download app

.app {
   width: 1100px;
   max-width: 90%;
   margin: 0 auto;
   display: flex;
   
   &__wrapper {
      margin: 0 0 200px 0;
      width: 100%;
      position: relative;

   }
   
   &__background {
      position: absolute;
      background: #f2f7fb;
      top: 50px;
      width: 100%;
      height: 340px;
      z-index: -2;
   }

   &__img {
      margin-right: 70px;
   }

   &__info {
      display: flex;
      flex-direction: column;
      margin-top: 110px;
      align-items: flex-start;
   }

   &__infoTitle {
      font-size: 24px;
      font-weight: 500;
      color: $darkBlue;
   }

   &__infoDesc {
      font-size: 18px;
      line-height: 28px;
      color: #494a4b;
      margin-top: 10px;
      text-align: start;
   }

   &__buttons {
      display: flex;
      flex-wrap: wrap;
   }

   &__btn {
      margin: 27px 30px 0 0;
      background: $pink;
      width: 260px;
      height: 72px;
      box-sizing: border-box;
      padding: 12px 18px;
      border-radius: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #ffffff;
      position: relative;
      transition: $transition;

      &:hover {
         background: darken($color: $pink, $amount: 6);
      }
   }

   &__btnImg {
      position: absolute;
      top: 0;
      right: 0;

   }

   &__btnDesc {
      display: flex;
      flex-direction: column;
      margin-left: 17px;
   }

   &__btnTextAppStore {
      font-size: 15px;
   }

   &__btnTextGooglePlay {
      font-size: 14px;
      text-transform: uppercase;
      text-align: start;
   }

   &__btnDescTitle {
      font-size: 26px;
      font-weight: 500;
      line-height: 25px;
   }

}

@media (max-width: 1100px) {
   
   .banner {
      margin-top: 50px;

      &__tabletImgBg {
         display: none;
      }

      &__tabletImg {
         position: absolute;
         top: -50px;
         right: 0px;
         display: block;

         img {
            width: 320px;
         }
      }
   }

   .selection {
      width: 100%;
   }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

   .app {

      &__wrapper {
         background: #f2f7fb;
         padding: 50px 0;
         margin: 150px 0;
      }

      &__background {
         display: none;
      }

      &__info {
         margin-top: 0;
      }
   }
 }

@media (max-width: 769px) {

   .banner {

      &__tabletImg {
         top: 150px;
         right: -50px;
      }
   }
   
   .app {

      flex-direction: column-reverse;
      align-items: center;

      &__img {
         margin-top: 40px;
         margin-right: 0;
      }

      &__wrapper {
         margin: 0;
      }

      &__buttons {
         justify-content: center;
      }

      &__btn {
         margin: 27px 0 0 0;
      }
   }
}