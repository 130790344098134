.popup {
   &__content {
      position: relative;
      padding: 60px 100px 50px 100px;
   }
}


@media (max-width: 1024px) {
   .popup {
      &__content {
         padding: 50px 100px;
      }
   }
}

@media (max-width: 640px) {
   .popup {
      &__content {
         padding: 30px;
      }
   }
}