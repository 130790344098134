.shelters {
    width: 1125px;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 20px;
    justify-content: space-between;
}